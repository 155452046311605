/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	ThunkCheckInFindAllUpsellingBalance,
	ThunkCheckInFindAllUpsellingPayment,
	ThunkCheckInFindAllUpsellingPurchaseIntent,
	ThunkCheckInFlexipassFindAllSmartLockList,
	ThunkCheckInHostFieldMapping,
	ThunkCheckInUpsellingProducts,
	ThunkCreateIntentPaymentHold,
	ThunkCreatePaymentHold,
	chGetTTLockList,
	chPostTTLockToken,
	getCheckinHostTemplates,
	getWhatsappMe,
	postWhatsappConnect,
	postWhatsappDisconnect,
	postWhatsappMessage,
	ThunkMakePolicyPayment,
	ThunkCheckInFinOneProduct
} from './thunks';

export const checkInHostSlice = createSlice({
	name: 'checkInHost',
	initialState,
	reducers: {
		resetWhatsappMessage: (state) => {
			state.whatsappMessage.loading = 'idle';
		},
		checkInHostUpdateFieldMapping: (state, { payload }) => {
			state.fieldMapping = payload;
		},
		updateUpsellingProduct: (state, { payload }) => {
			if (state.upsellingProducts.data) {
				const newArray = current(state.upsellingProducts.data);

				const data = newArray.map((e) => {
					if (e._id === payload.productId)
						return {
							...e,
							active: true,
							children: !e.children.some(
								(e: any) => e.settingsId === payload._id
							)
								? [
										...e.children,
										{
											...payload,
											settingsId: payload._id
										}
								  ]
								: e.children.map((j: any) =>
										j.settingsId === payload._id
											? {
													...j,
													active: payload.active,
													settings: payload.settings
											  }
											: j
								  )
						};
					return e;
				});

				state.upsellingProducts.data = data;
			}
		},
		disabledUpsellingProduct: (state, { payload }) => {
			if (state.upsellingProducts.data) {
				const newArray = current(state.upsellingProducts.data);

				const data = newArray.map((e) => {
					if (e._id === payload.productId)
						return {
							...e,
							active: payload.active,
							children: e.children.map((j: any) => ({
								...j,
								active: payload.active
							}))
						};
					return e;
				});
				state.upsellingProducts.data = data;
			}
		},
		deleteUpsellingProductSettings: (state, { payload }) => {
			const newArray = current(state.upsellingProducts.data);

			if (newArray) {
				const data = newArray.map((e) => {
					if (e._id === payload.productId) {
						return {
							...e,
							children: e.children.filter(
								(e: any) => e.settingsId !== payload.settingsId
							)
						};
					}
					return e;
				});

				state.upsellingProducts.data = data;
			}
		},
		updateUpsellingPayment: (state, { payload }) => {
			if (state.upsellingPayment.payload)
				state.upsellingPayment.payload.data = payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getWhatsappMe.pending, (state) => {
				state.whatsappMe.loading = 'pending';
			})
			.addCase(getWhatsappMe.rejected, (state, { payload }) => {
				state.whatsappMe.loading = 'failed';
				state.whatsappMe.error = payload;
			})
			.addCase(getWhatsappMe.fulfilled, (state, { payload }) => {
				state.whatsappMe.loading = 'succeeded';
				state.whatsappMe.data = payload;
			});

		builder
			.addCase(postWhatsappConnect.pending, (state) => {
				state.whatsappConnect.loading = 'pending';
			})
			.addCase(postWhatsappConnect.rejected, (state, { payload }) => {
				state.whatsappConnect.loading = 'failed';
				state.whatsappConnect.error = payload;
			})
			.addCase(postWhatsappConnect.fulfilled, (state, { payload }) => {
				state.whatsappConnect.loading = 'succeeded';
			});

		builder
			.addCase(postWhatsappDisconnect.pending, (state) => {
				state.whatsappDisconnect.loading = 'pending';
			})
			.addCase(postWhatsappDisconnect.rejected, (state, { payload }) => {
				state.whatsappDisconnect.loading = 'failed';
				state.whatsappDisconnect.error = payload;
			})
			.addCase(postWhatsappDisconnect.fulfilled, (state) => {
				state.whatsappMe = {
					loading: 'idle'
				};
				state.whatsappConnect = {
					loading: 'idle'
				};
				state.whatsappDisconnect.loading = 'succeeded';
			});

		builder
			.addCase(postWhatsappMessage.pending, (state) => {
				state.whatsappMessage.loading = 'pending';
			})
			.addCase(postWhatsappMessage.rejected, (state, { payload }) => {
				state.whatsappMessage.loading = 'failed';
				state.whatsappMessage.error = payload;
			})
			.addCase(postWhatsappMessage.fulfilled, (state) => {
				state.whatsappMessage.loading = 'succeeded';
			});

		builder
			.addCase(getCheckinHostTemplates.pending, (state) => {
				state.templates.loading = 'pending';
			})
			.addCase(getCheckinHostTemplates.rejected, (state, { payload }) => {
				state.templates.loading = 'failed';
				state.templates.error = payload;
			})
			.addCase(
				getCheckinHostTemplates.fulfilled,
				(state, { payload }) => {
					state.templates = {
						loading: 'succeeded',
						data: payload
					};
				}
			);

		builder
			.addCase(chPostTTLockToken.pending, (state) => {
				state.ttlockToken = { loading: 'pending' };
			})
			.addCase(chPostTTLockToken.rejected, (state, { payload }) => {
				state.ttlockToken = {
					loading: 'failed',
					error: payload
				};
			})
			.addCase(chPostTTLockToken.fulfilled, (state, { payload }) => {
				state.ttlockToken = {
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(chGetTTLockList.pending, (state) => {
				state.ttlockList.loading = 'pending';
			})
			.addCase(chGetTTLockList.rejected, (state, { payload }) => {
				state.ttlockList.loading = 'failed';
				state.ttlockList.error = payload;
			})
			.addCase(chGetTTLockList.fulfilled, (state, { payload }) => {
				state.ttlockList.loading = 'succeeded';

				if (!state.ttlockList.data) {
					state.ttlockList.data = payload;
				} else {
					if (payload.pageNo === 1) {
						state.ttlockList.data = payload;
					} else {
						state.ttlockList.data = {
							...state.ttlockList.data,
							...payload,
							list: [
								...state.ttlockList.data.list,
								...payload.list
							]
						};
					}
				}
			});

		builder
			.addCase(ThunkCheckInHostFieldMapping.pending, (state) => {
				state.fieldMapping.loading = 'pending';
			})
			.addCase(
				ThunkCheckInHostFieldMapping.rejected,
				(state, { payload }: any) => {
					state.fieldMapping = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCheckInHostFieldMapping.fulfilled,
				(state, { payload }: any) => {
					state.fieldMapping = {
						loading: 'succeeded',
						payload: payload
					};
				}
			);

		builder
			.addCase(ThunkCreatePaymentHold.pending, (state) => {
				state.paymentHold.loading = 'pending';
			})
			.addCase(
				ThunkCreatePaymentHold.rejected,
				(state, { payload }: any) => {
					state.paymentHold = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCreatePaymentHold.fulfilled,
				(state, { payload }: any) => {
					state.paymentHold = {
						loading: 'succeeded',
						payload: payload
					};
				}
			);

		builder
			.addCase(ThunkCreateIntentPaymentHold.pending, (state) => {
				state.intentPaymentHold.loading = 'pending';
			})
			.addCase(
				ThunkCreateIntentPaymentHold.rejected,
				(state, { payload }: any) => {
					state.intentPaymentHold = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCreateIntentPaymentHold.fulfilled,
				(state, { payload }: any) => {
					state.intentPaymentHold = {
						loading: 'succeeded',
						payload: payload
					};
				}
			);

		builder
			.addCase(ThunkCheckInUpsellingProducts.pending, (state) => {
				state.upsellingProducts.loading = 'pending';
			})
			.addCase(
				ThunkCheckInUpsellingProducts.rejected,
				(state, { payload }) => {
					state.upsellingProducts = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCheckInUpsellingProducts.fulfilled,
				(state, { payload }) => {
					state.upsellingProducts = {
						loading: 'succeeded',
						data: payload
					};
				}
			);

		builder
			.addCase(ThunkCheckInFindAllUpsellingPayment.pending, (state) => {
				state.upsellingPayment.loading = 'pending';
			})
			.addCase(
				ThunkCheckInFindAllUpsellingPayment.rejected,
				(state, { payload }: any) => {
					state.upsellingPayment = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCheckInFindAllUpsellingPayment.fulfilled,
				(state, { payload }) => {
					state.upsellingPayment = {
						loading: 'succeeded',
						payload: payload
					};
				}
			);

		builder
			.addCase(ThunkMakePolicyPayment.pending, (state) => {
				state.policyPayment.loading = 'pending';
			})
			.addCase(
				ThunkMakePolicyPayment.rejected,
				(state, { payload }: any) => {
					state.policyPayment = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(ThunkMakePolicyPayment.fulfilled, (state, { payload }) => {
				state.policyPayment = {
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(ThunkCheckInFinOneProduct.pending, (state) => {
				state.product.loading = 'pending';
			})
			.addCase(
				ThunkCheckInFinOneProduct.rejected,
				(state, { payload }: any) => {
					state.product = {
						loading: 'failed',
						error: String(JSON.stringify(payload))
					};
				}
			)
			.addCase(
				ThunkCheckInFinOneProduct.fulfilled,
				(state, { payload }) => {
					state.product = {
						loading: 'succeeded',
						data: payload
					};
				}
			);
		builder
			.addCase(
				ThunkCheckInFindAllUpsellingPurchaseIntent.pending,
				(state) => {
					state.upsellingPurchaseIntent.loading = 'pending';
				}
			)
			.addCase(
				ThunkCheckInFindAllUpsellingPurchaseIntent.rejected,
				(state, { payload }: any) => {
					state.upsellingPurchaseIntent = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCheckInFindAllUpsellingPurchaseIntent.fulfilled,
				(state, { payload }) => {
					state.upsellingPurchaseIntent = {
						loading: 'succeeded',
						payload: payload
					};
				}
			);

		builder
			.addCase(ThunkCheckInFindAllUpsellingBalance.pending, (state) => {
				state.upsellingBalance.loading = 'pending';
			})
			.addCase(
				ThunkCheckInFindAllUpsellingBalance.rejected,
				(state, { payload }: any) => {
					state.upsellingBalance = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCheckInFindAllUpsellingBalance.fulfilled,
				(state, { payload }) => {
					state.upsellingBalance = {
						loading: 'succeeded',
						payload: payload
					};
				}
			);

		builder
			.addCase(
				ThunkCheckInFlexipassFindAllSmartLockList.pending,
				(state) => {
					state.flexipassList.loading = 'pending';
				}
			)
			.addCase(
				ThunkCheckInFlexipassFindAllSmartLockList.rejected,
				(state, { payload }) => {
					state.flexipassList.loading = 'failed';
					state.flexipassList.error = payload;
				}
			)
			.addCase(
				ThunkCheckInFlexipassFindAllSmartLockList.fulfilled,
				(state, { payload }) => {
					state.flexipassList.loading = 'succeeded';
					state.flexipassList.data = payload;
				}
			);
	}
});

export const {
	resetWhatsappMessage,
	checkInHostUpdateFieldMapping,
	updateUpsellingProduct,
	disabledUpsellingProduct,
	updateUpsellingPayment,
	deleteUpsellingProductSettings
} = checkInHostSlice.actions;
