/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon } from '@hospy/hospy-ui';
import { useCallback, useMemo } from 'react';
import {
	Editor,
	Element as SlateElement,
	Transforms,
	createEditor
} from 'slate';
import { Editable, Slate, useSlate, withReact } from 'slate-react';
import styled from 'styled-components';
import { Button, Toolbar } from './components';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

interface IText {
	text: string;
	bold?: boolean;
	underline?: boolean;
}

const GetHtmlText = ({ text, bold }: IText) => {
	let paragraph = `{{text}}`;
	if (bold)
		paragraph = paragraph.replace('{{text}}', `<strong>${text}</strong>`);
	paragraph = paragraph.replace('{{text}}', `${text}`);

	return paragraph;
};

const serialize = (nodes: any) => {
	const formatted = nodes?.map((node: any) => {
		switch (node.type) {
			case 'paragraph':
				return node.children
					.map((children: any) => `<p>${GetHtmlText(children)}</p>`)
					.join('');

			case 'heading-one':
				return node.children
					.map((children: any) => `<h1>${GetHtmlText(children)}</h1>`)
					.join('');

			case 'heading-two':
				return node.children
					.map((children: any) => `<h2>${GetHtmlText(children)}</h2>`)
					.join('');

			case 'numbered-list':
				return `<ul>${serialize(node.children).join('')}</ul>`;

			case 'bulleted-list':
				return `<ul>${serialize(node.children).join('')}</ul>`;

			case 'list-item':
				return node.children
					.map((children: any) => `<li>${GetHtmlText(children)}</li>`)
					.join('');

			default:
				return '';
		}
	});

	return formatted;
};

interface IRichText {
	onChange: (string: any, rawJson: any) => void;
	initialValue: any;
	placeholder?: string;
}

export const RichText = ({
	onChange,
	initialValue,
	placeholder
}: IRichText) => {
	const renderElement = useCallback(
		(props: any) => <Element {...props} />,
		[]
	);
	const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
	const editor = useMemo(() => withReact(createEditor()), []);

	return (
		<Slate
			editor={editor}
			value={initialValue}
			onChange={(value) =>
				onChange(`<div>${serialize(value).join('\n')}</div>`, value)
			}
		>
			<Toolbar>
				<BlockButton format="heading-one" icon="format_h1" />
				<BlockButton format="heading-two" icon="format_h2" />
				<MarkButton format="bold" icon="format_bold" />
				<MarkButton format="italic" icon="format_italic" />

				<BlockButton
					format="numbered-list"
					icon="format_list_numbered"
				/>
				<BlockButton
					format="bulleted-list"
					icon="format_list_bulleted"
				/>
			</Toolbar>
			<EditableWrapper
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				placeholder={placeholder ?? 'Ingresa tu texto aquí'}
				spellCheck
			/>
		</Slate>
	);
};

const toggleBlock = (editor: any, format: any) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
	);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			LIST_TYPES.includes(n.type) &&
			!TEXT_ALIGN_TYPES.includes(format),
		split: true
	});
	let newProperties: Partial<SlateElement>;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format
		} as any;
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format
		};
	}
	Transforms.setNodes<SlateElement>(editor, newProperties);

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const toggleMark = (editor: any, format: any) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

const isBlockActive = (editor: any, format: any, blockType = 'type') => {
	const { selection } = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: (n) =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n[blockType as keyof typeof n] === format
		})
	);

	return !!match;
};

const isMarkActive = (editor: any, format: any) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format as keyof typeof marks] === true : false;
};

const Element = ({ attributes, children, element }: any) => {
	const style = { textAlign: element.align };
	switch (element.type) {
		case 'block-quote':
			return (
				<blockquote style={style} {...attributes}>
					{children}
				</blockquote>
			);
		case 'bulleted-list':
			return (
				<ul style={{ ...style, marginLeft: 17 }} {...attributes}>
					{children}
				</ul>
			);
		case 'heading-one':
			return (
				<h1 style={style} {...attributes}>
					{children}
				</h1>
			);
		case 'heading-two':
			return (
				<h2 style={style} {...attributes}>
					{children}
				</h2>
			);
		case 'list-item':
			return (
				<li style={style} {...attributes}>
					{children}
				</li>
			);
		case 'numbered-list':
			return (
				<ol style={style} {...attributes}>
					{children}
				</ol>
			);
		default:
			return (
				<p style={style} {...attributes}>
					{children}
				</p>
			);
	}
};

const Leaf = ({ attributes, children, leaf }: any) => {
	if (leaf.bold) {
		children = <strong style={{ fontWeight: 'bold' }}>{children}</strong>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: any) => {
	const editor = useSlate();
	return (
		<IconButtons
			active={isBlockActive(
				editor,
				format,
				TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
			)}
			onMouseDown={(event: any) => {
				event.preventDefault();
				toggleBlock(editor, format);
			}}
		>
			<Icon ant materialOutlined={icon}></Icon>
		</IconButtons>
	);
};

const MarkButton = ({ format, icon }: any) => {
	const editor = useSlate();
	return (
		<IconButtons
			active={isMarkActive(editor, format)}
			onMouseDown={(event: any) => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
		>
			<Icon ant materialOutlined={icon} />
		</IconButtons>
	);
};

const EditableWrapper = styled(Editable)`
	min-height: 170px !important;
	padding: 15px;
`;

const IconButtons = styled(Button)`
	svg {
		font-size: ${({ theme }) => theme.title.size.large}px;
	}
`;
