import { fetchAddWaitList, useAppDispatch, useAppSelector } from '@hospy/store';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const useAppCard = () => {
	const { isAuth } = useAppSelector(({ user }) => user);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const addMeWaitingList = (_id: string, name: any) => {
		if (!isAuth) {
			message.info(
				'Ingresa o crea tu cuenta para agregar la aplicación a la lista de espera'
			);
			navigate('/login', {
				state: {
					pathname: location.pathname,
					search: location.search,
					activateApp: true
				}
			});
			return;
		} else {
			dispatch(fetchAddWaitList(_id));
		}
	};

	return {
		addMeWaitingList
	};
};

export default useAppCard;
