import {
	accountRoute,
	lazyWithRetry
} from '@hospy/shared-components';
import { useRoutes } from 'react-router-dom';

const SetupComplete = lazyWithRetry(
	() => import('@start-pages/setup-complete')
);

const PrivateLayout = lazyWithRetry(
	() => import('@start/components/layouts/private-layout')
);

const Dashboard = lazyWithRetry(
	() => import('@start/components/layouts/dashboard')
);

export const AppRoute = () => {
	let element = useRoutes([
		...accountRoute.map(({ path, component: Cmp }) => ({
			path,
			element: <Cmp />
		})),
		{
			path: 'stripe/setup-complete',
			element: <SetupComplete />
		},
		{
			element: <PrivateLayout />,
			children: [
				{
					path: '*',
					element: <Dashboard />
				}
			]
		}
	]);

	return element;
};

export default AppRoute;
