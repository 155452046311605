/* eslint-disable @typescript-eslint/no-explicit-any */
import Eye from '@2fd/ant-design-icons/lib/Eye';
import { MenuOutlined } from '@ant-design/icons';
import { Button as HospyBtn, Icon as IconRenderer } from '@hospy/hospy-ui';
import { AppData } from '@hospy/store';
import { Drawer as AntDrawer, Button, Space, Tooltip, Typography } from 'antd';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { HospyIcon } from '../../atoms/icons';
import AppSelectApp from '../app-select-app';
import HospyAlertModal from '../hospy-alert-modal';
import { DrawerMenu } from '../nav-bar/nav-bar-menu';
import AppStatus from './app-status';
import UseAppNavBar from './use-app-nav-bar';

interface AppNavBarProps {
	appData: undefined | AppData;
	daysToExpire: number | null;
	icon?: React.ElementType;
	title: string;
	titleRight?: string;
	menu?: React.ReactElement;
	hideSelectProperty?: boolean;
}
const AppNavBarV2 = ({
	icon: Icon,
	title,
	titleRight,
	menu,
	hideSelectProperty,
	appData,
	daysToExpire
}: AppNavBarProps) => {
	const {
		setOpen,
		user,
		theme,
		buy,
		exitViewUser,
		modalBuyDenied,
		open,
		sProperty,
		setModalBuyDenied
	} = UseAppNavBar({ hideSelectProperty, title });

	const [hidden, setHidden] = useState(false);
	const { scrollY } = useScroll();

	useMotionValueEvent(scrollY, 'change', (latest) => {
		const previous = scrollY.getPrevious();
		if (theme.screens.xs) {
			if (latest > (previous || 0) && latest > 70) {
				setHidden(true);
			} else {
				setHidden(false);
			}
		} else {
			setHidden(false);
		}
	});

	const expirationWarning = (
		<ExpirationWarning>
			<ExpirationWarningLeftSide>
				<IconRenderer material="warning" />
				<Typography.Text>
					<FormattedMessage
						id="header.expirationWarning"
						defaultMessage=" {days, plural, =0 {Prueba vencida} one {Tu prueba vence en # día} other {Tu prueba vence en # días}}"
						values={{
							days: daysToExpire
						}}
					/>
				</Typography.Text>
			</ExpirationWarningLeftSide>
			<HospyBtn
				size="small"
				onClick={buy}
				style={{ padding: '5px 15px', height: 'auto' }}
			>
				Comprar ahora
			</HospyBtn>
		</ExpirationWarning>
	);

	const showExpirationWarning =
		appData?.pricingModel !== 'free' && daysToExpire !== null;

	const visibleAlert: boolean =
		showExpirationWarning &&
		(theme.screens['xs'] || (theme.screens['sm'] && !theme.screens['lg']));

	const variants = {
		hidden: { y: 0 },
		visible: { y: 80 }
	};

	return (
		<>
			<Header
				visibleAlert={visibleAlert}
				animate={hidden ? 'hidden' : 'visible'}
				variants={{
					hidden: { y: -80 },
					visible: { y: 0 }
				}}
				transition={{ duration: 0.15 }}
			>
				<HeaderLeft>
					{theme.screens.xs && (
						<Link to="/">
							<HospyIcon style={{ height: 30, width: 24.81 }} />
						</Link>
					)}
					<Space>
						<SelectPropertyContainer>
							{titleRight && (
								<TitleRight>{titleRight}</TitleRight>
							)}
							{sProperty}
						</SelectPropertyContainer>

						{user?.userViewAdmin && theme.screens['lg'] && (
							<Tooltip
								placement="bottom"
								title="Volver al módulo de usuarios"
							>
								<Button
									onClick={exitViewUser}
									icon={<Eye />}
									size="large"
									type="text"
									shape="circle"
								/>
							</Tooltip>
						)}
					</Space>
				</HeaderLeft>
				<HeaderRight>
					{showExpirationWarning && (
						<SectionExpiration>
							{expirationWarning}
						</SectionExpiration>
					)}

					{!theme.screens.xs &&
						appData?.maintenance &&
						appData?.maintenanceMessage && <AppStatus />}
					<Title>
						{!theme.screens.xs && Icon && (
							<Icon style={{ marginRight: 10, fontSize: 25 }} />
						)}
						{title}
					</Title>
					<SelectPropertyContainerXs>
						<AppSelectApp
							style={{ padding: 0, height: 30, width: 30 }}
							title={title}
						/>
						<Button
							type="text"
							size="middle"
							icon={<MenuOutlined style={{ fontSize: 30 }} />}
							style={{
								padding: 0,
								marginLeft: 10,
								height: 30,
								width: 30
							}}
							onClick={() => setOpen(true)}
						/>
					</SelectPropertyContainerXs>
					<Drawer
						open={open}
						onClose={() => setOpen(false)}
						closable={false}
						placement="top"
						height={'90%'}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								padding: '0 15px'
							}}
						>
							<Button
								type="text"
								icon={
									<IconRenderer
										style={{ fontSize: 30 }}
										material="close"
										ant
									/>
								}
								onClick={() => setOpen(false)}
							/>
						</div>
						<DrawerMenu
							appName={title}
							onCloseDrawer={() => setOpen(false)}
							hideAddPropertyButton={true}
							menu={menu}
						/>
					</Drawer>
				</HeaderRight>
			</Header>
			{showExpirationWarning && (
				<>
					<SectionExpirationSmall
						animate={hidden ? 'hidden' : 'visible'}
						variants={variants}
						transition={{ duration: 0.15 }}
					>
						{expirationWarning}
					</SectionExpirationSmall>
					<SectionExpirationMiddle>
						{expirationWarning}
					</SectionExpirationMiddle>
				</>
			)}
			<HospyAlertModal
				open={modalBuyDenied.open}
				onCancel={() => setModalBuyDenied({ open: false, name: '' })}
				title={
					<FormattedMessage
						id="header.buy-denied.title"
						defaultMessage="Acceso no permitido"
					/>
				}
				subtitle={
					<>
						<FormattedMessage
							id="app-activation-denied.subTitle"
							defaultMessage="No puedes comprar esta aplicación porque no eres el propietario del negocio"
						/>
						<b>{modalBuyDenied.name}</b>
					</>
				}
			/>
		</>
	);
};

export default AppNavBarV2;

const SectionExpiration = styled.div`
	display: none;
	@media screen and (min-width: 991px) {
		display: initial;
	}
	@media screen and (min-width: 576px) and (max-width: 990px) {
		display: none;
	}
`;

const SectionExpirationMiddle = styled.div`
	display: none;
	@media screen and (min-width: 576px) and (max-width: 990px) {
		display: initial;
		position: fixed;
		z-index: 4;
		width: 100%;
		top: 65px;
		max-width: 350px;
		left: 80px;
	}
`;

const SectionExpirationSmall = styled(motion.div)`
	display: none;
	@media screen and (max-width: 576px) {
		display: initial;
		position: fixed;
		z-index: 3;
		width: 100%;
	}
`;

const Title = styled(Typography.Text)`
	font-size: 15px;
	align-items: center;
	display: flex;
	color: ${({ theme }) => theme.colors.primary.base};
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			color: #fff;
			margin-left: 15px;
		`}
`;

const SelectPropertyContainer = styled.div`
	display: none;
	${({ theme }) =>
		!theme.screens['xs'] &&
		css`
			display: flex;
			gap: 15px;
			align-items: center;
		`}
`;

const SelectPropertyContainerXs = styled.div`
	display: none;
	& .ant-btn {
		color: #fff;
	}
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			display: flex;
			margin-left: 20px;
		`}
`;

const TitleRight = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.large}px;
	color: ${({ theme }) => theme.colors.gray.base};
	line-height: 1;
`;

const Drawer = styled(AntDrawer)`
	.ant-drawer-body {
		padding: 24px 0;
	}
`;

const ExpirationWarning = styled.div`
	padding: 5px 10px;
	padding-left: 15px;
	align-items: center;
	gap: 20px;
	display: flex;
	background-color: ${({ theme }) => theme.colors.danger.lighten['90%']};
	border-radius: 50px;
	svg {
		color: ${({ theme }) => theme.colors.danger.base};
		font-size: ${({ theme }) => theme.title.size.small}px;
	}
	button {
		border: none;
		font-weight: bold;
		height: 40px;
		background-color: inherit;
		display: flex;
		align-items: center;
		border-radius: 50px;
		:hover,
		:active,
		:focus {
			background-color: ${({ theme }) =>
				theme.colors.danger.lighten &&
				theme.colors.danger.lighten['80%']} !important;
		}
	}

	.jopsAt.ant-btn {
		&:focus:not(:disabled),
		&:hover:not(:disabled) {
			background-color: ${({ theme }) =>
				theme.colors.danger.lighten['90%']};
		}
	}

	@media screen and (max-width: 576px) {
		width: 100%;
		margin: 0;
		justify-content: space-between;
		border-radius: 0;
		font-size: 12px;
		padding: 10px !important;
		padding-left: 15px;
		button {
			font-size: ${({ theme }) => theme.text.size.middle}px;
		}
		padding: 0 5px;
		gap: 5px;
		backdrop-filter: blur(4px);
		background-color: ${({ theme }) =>
			theme.colors.danger.lighten['90%']}c4;
	}

	@media screen and (min-width: 576px) and (max-width: 990px) {
		width: 100%;
		justify-content: space-between;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
`;

const ExpirationWarningLeftSide = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	height: 100%;
	line-height: 22px;
`;

const HeaderLeft = styled.div`
	line-height: 1;
`;

const HeaderRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: auto;
	gap: 14px;
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			justify-content: space-between;
			width: 100%;
		`}
`;

interface HeaderProps {
	readonly visibleAlert?: boolean;
	readonly visibleExpirationAlert?: boolean;
}

const Header = styled(motion.div)<HeaderProps>`
	width: calc(100% - 80px);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lighten['50%']};
	padding-inline: 15px;
	height: auto;
	min-height: 80px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 4;
	background-color: #fff;
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			width: 100%;
			background-color: ${({ theme }) => theme.colors.primary.base};
		`}
`;
