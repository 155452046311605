/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from '@hospy/store';
import { Layout as AntLayout } from 'antd';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import AddPhoneChannelPropertyModal from '../../../components/add-phone-channel-property-modal/add-phone-channel-property-modal';

interface WpProps {
	readonly showAlert?: boolean;
}
export const Wp = styled.div<WpProps>`
	position: relative;
	width: calc(100% - 80px);
	min-height: calc(100dvh - 81px);
	margin-top: 81px;
	margin-left: 80px;
	/* overflow-x: hidden; */

	${({ showAlert }) =>
		showAlert &&
		css`
			height: calc(100dvh - 131px);
			margin-top: 131px;
		`}

	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			width: 100%;
			margin-left: 0;
			margin-bottom: 66px;
		`}
`;

interface MainProps {
	readonly fullWidth?: boolean;
	readonly visibleExpirationAlert?: boolean;
}

export const Main = styled(AntLayout)<MainProps>`
	width: 100%;
	/* padding-inline: 15px; */
	margin-inline: auto;
	max-width: ${({ theme }) => theme['maxWidth']}px;
	${({ fullWidth }) =>
		fullWidth &&
		css`
			max-width: 100%;
		`}
`;

export const LoadingChangeProperty = styled.div`
	position: absolute;
	width: 100%;
	min-height: 100%;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(5px);
`;

export const UpdateExtraPropertyInfoModal = () => {
	const { property } = useAppSelector(({ user }) => user);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!property) return;
		if (!property?.phone) setOpen(true);
	}, [property?._id]);

	return (
		<AddPhoneChannelPropertyModal
			open={open}
			onClose={() => setOpen(false)}
		/>
	);
};

interface IMenuItemProps {
	isSelected?: boolean;
}
export const MenuItemContainer = styled.div<IMenuItemProps>`
	position: relative;
	background-color: ${({ theme, isSelected }: any) =>
		isSelected
			? theme.colors.primary.darken['50%']
			: theme.colors.primary.base};
	&:hover {
		background-color: ${({ theme, isSelected }: any) =>
			isSelected
				? theme.colors.primary.darken['30%']
				: theme.colors.primary.lighten['20%']};
	}
	> a {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		padding: 15px 0;
		gap: 8px;
		position: relative;
		z-index: 2;

		${({ theme }) =>
			theme.screens['xs'] &&
			css`
				padding: 8px 0px;
			`}
	}

	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			padding: 0 15px;
			min-width: 93px;
		`}
`;
