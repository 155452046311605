/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Icon } from '@hospy/hospy-ui';
import { IReservationInvoice, Invoice, PARTNER } from '@hospy/store';
import { currencyFormatter } from '@hospy/util-common';
import { Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

interface IInvoiceItemCard {
	reservation: IReservationInvoice;
	selectInvoice: () => void;
	detailOpened: boolean;
	isSelected: boolean;
	invoice?: Invoice;
	invoiceLoading: boolean;
	multipleInvoices?: boolean;
	partnerIntegrationId: string;
	selectToBulk?: boolean;
	onSelectBulk?: (reservation: IReservationInvoice) => void;
	hideBulk?: boolean;
	forcePrice?: boolean;
}

const invoiceStampStatusText = (
	stampStatus: string,
	invoices?: number,
	multipleInvoices?: boolean
) => {
	if (invoices && multipleInvoices)
		return (
			<FormattedMessage
				defaultMessage="{total, plural, =0 {facturas} one {# factura} other {# facturas}}"
				values={{ total: invoices }}
			/>
		);
	if (stampStatus === 'accepted' || stampStatus === 'canceled-pending')
		return 'Facturado';
	if (stampStatus === 'draft' || stampStatus === 'rejected')
		return 'En proceso';
	return 'Sin facturar';
};

const InvoiceItemCard = ({
	reservation,
	selectInvoice,
	isSelected,
	invoice,
	invoiceLoading,
	multipleInvoices,
	partnerIntegrationId,
	selectToBulk,
	onSelectBulk,
	hideBulk,
	forcePrice
}: IInvoiceItemCard) => {
	const [showMarkBulk, setShowMarkBulk] = useState(false);
	const renderPrice = () => {
		if (!isSelected && !forcePrice)
			return invoiceStampStatusText(
				reservation.stampStatus,
				reservation.invoices,
				multipleInvoices
			);
		if (reservation.stampStatus && !forcePrice)
			return invoiceStampStatusText(
				reservation.stampStatus,
				reservation.invoices,
				multipleInvoices
			);

		if (reservation.channelIcon?.includes('cloudbeds') || forcePrice)
			return currencyFormatter.format(
				invoice?.total || +(reservation.total || 0),
				'USD',
				2,
				'symbol'
			);
		return invoiceStampStatusText(
			reservation.stampStatus,
			reservation.invoices,
			multipleInvoices
		);
	};

	const renderSelectInvoiceToBulkCreate =
		partnerIntegrationId === PARTNER.AIRBNB &&
		!reservation.stampStatus &&
		!hideBulk &&
		(showMarkBulk || selectToBulk);

	return (
		<InvoiceWrapper
			onMouseLeave={() => {
				setShowMarkBulk(false);
			}}
			onMouseEnter={() => {
				setShowMarkBulk(true);
			}}
			isSelected={isSelected}
			onClick={selectInvoice}
		>
			{renderSelectInvoiceToBulkCreate && (
				<SelectBulk
					isSelected={selectToBulk}
					onClick={(e: any) => {
						e.stopPropagation();
						onSelectBulk && onSelectBulk(reservation);
					}}
					type="text"
					tooltip="Marcar para facturar en grupo"
					icon={<Icon style={{ fontSize: 30 }} material="bookmark" />}
				/>
			)}
			{reservation.totalImported && (
				<MarkImported>
					<Tooltip title="El  valor de esta reserva fue actualizado con el archivo de Airbnb">
						<Icon material="verified" />
					</Tooltip>
				</MarkImported>
			)}
			<ChannelInfo>
				<img
					width={30}
					height={30}
					src={reservation.channelIcon}
					alt="partner icon"
				/>
			</ChannelInfo>
			<MainInfo>
				<CustomerInfo status={reservation.status}>
					<Customer>
						<Name>{reservation.guestName}</Name>
					</Customer>
					<CustomerId>
						ID {reservation.reservationID}{' '}
						{reservation.roomName && `/ ${reservation.roomName}`}
					</CustomerId>
				</CustomerInfo>
			</MainInfo>
			<SecondaryInfo>
				<PriceWrapper>
					<Price
						isSelected={isSelected}
						stampStatus={reservation.stampStatus}
					>
						{invoiceLoading && isSelected && (
							<LoadingOutlined spin />
						)}
						{invoiceLoading && !isSelected && (
							<p>
								{invoiceStampStatusText(
									reservation.stampStatus,
									reservation.invoices,
									multipleInvoices
								)}
							</p>
						)}
						{!invoiceLoading && <p>{renderPrice()}</p>}
					</Price>
				</PriceWrapper>
			</SecondaryInfo>
			{reservation.stampStatus === 'rejected' && (
				<CheckSection hasError>
					<DangerIcon style={{ color: 'red' }} material="error" />
				</CheckSection>
			)}
		</InvoiceWrapper>
	);
};

const InvoiceWrapper = styled(({ isSelected, ...props }) => (
	<button {...props} />
))`
	position: relative;
	min-width: 380px;
	height: 88px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 15px;
	padding: 1rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.base};
	background-color: white;
	box-shadow: ${({ theme }) => theme.boxShadow.level1()};
	cursor: pointer;
	${({ isSelected }) =>
		isSelected
			? css`
					box-shadow: ${({ theme }) =>
						theme.boxShadow.level1('#cde8f0')};
					border: none;
					background-color: #edfbff;
					:after {
						content: '';
						position: absolute;
						height: 0;
						width: 0;
						left: 100%;
						top: 0;
						border: 44px solid transparent;
						border-left: 28px solid #edfbff;
						z-index: 1;
					}
					:hover {
						background-color: #edfbff !important;
					}
					:active {
						background-color: #edfbff !important;
					}
			  `
			: css`
					:hover {
						border: 1px solid #36d3fe;
					}
			  `}
`;

const CustomerInfo = styled(({ status, ...props }) => <div {...props} />)`
	${({ status, theme }) =>
		(status === 'no_show' || status === 'canceled') &&
		css`
			span {
				color: ${theme.colors.danger.base};
			}
		`}
	display: flex;
	flex-direction: column;
	span {
		text-align: start;
	}
`;

const MainInfo = styled.div`
	width: 68%;
	display: flex;
	flex-direction: row;
	gap: 8px;
	span {
		text-align: start;
	}
`;

const SecondaryInfo = styled.div`
	display: flex;
	justify-content: end;
	height: 100%;
	min-width: 140px;
	${({ theme }: any) =>
		theme.screens.sm &&
		css`
			flex-direction: row;
			gap: 20px;
		`}
`;

const PriceWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Price = styled(({ isSelected, stampStatus, ...props }) => (
	<div {...props} />
))`
	background-color: ${({ theme }) => theme.colors.gray.lighten['20%']};
	color: ${({ theme }) => theme.colors.black.base};

	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
	padding-top: 2px;
	border-radius: 20px;
	height: 2.2rem;
	p {
		font-size: ${({ theme }) => theme.text.size.small}px;
		margin-bottom: 0;
	}

	${({ stampStatus, theme }) => {
		let stampColor = {
			background: '',
			color: theme.colors.white.base
		};
		if (stampStatus === '' || stampStatus === undefined || !stampStatus)
			stampColor = {
				background: theme.colors.gray.lighten['30%'],
				color: theme.colors.black.base
			};

		if (stampStatus === 'accepted' || stampStatus === 'canceled-pending')
			stampColor.background = theme.colors.success.base;

		if (stampStatus === 'draft' || stampStatus === 'rejected')
			stampColor.background = '#00c8f8';

		if (stampStatus === 'deleted')
			stampColor.background = theme.colors.gray.darken['40%'];

		return css`
			background-color: ${stampColor.background};
			color: ${stampColor.color};
			p {
				color: ${stampColor.color};
			}
		`;
	}}
`;

const Customer = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	gap: 20px;
`;

const Name = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.small}px;
	text-align: start;
	max-width: 160px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const CustomerId = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.text.size.small}px;
	max-width: 160px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const DangerIcon = styled(Icon)`
	color: ${({ theme }) => theme.colors.primary.base};
	font-size: ${({ theme }) => theme.text.size.large}px;
`;

const CheckSection = styled(({ hasError, ...props }) => <div {...props} />)`
	position: absolute;
	left: 97%;
	bottom: 79%;
	z-index: 2;
	svg {
		font-size: 24px;
	}
	${({ hasError, theme }) =>
		hasError &&
		css`
			left: 96%;
			bottom: 70%;
			svg {
				color: ${theme.colors.danger.base};
				font-size: 36px;
			}
		`}
`;

const ChannelInfo = styled.div`
	display: flex;
	align-items: center;

	height: 100%;
`;

const MarkImported = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	height: 40px;
	width: 40px;
	color: ${({ theme }) => `${theme.colors.white.base}`};
	clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);
	background-color: #3d8af7;

	svg {
		font-size: ${({ theme }) => theme.text.size.middle}px;
		color: white;
		margin-right: 18px;
		margin-top: 4px;
	}
`;

const SelectBulk = styled(({ isSelected, ...props }) => <Button {...props} />)`
	width: 30px !important;
	height: 30px !important;
	padding: 0 !important;
	position: absolute;
	top: -6px;
	right: 0;
	height: 40px;
	width: 40px;

	${({ isSelected, theme }) =>
		isSelected &&
		css`
			color: ${theme.colors.success.base};
			:focus,
			:active,
			:hover {
				color: ${theme.colors.success.base};
			}
		`}
`;

export default InvoiceItemCard;
