/* eslint-disable @typescript-eslint/no-explicit-any */
import Eye from '@2fd/ant-design-icons/lib/Eye';
import { MenuOutlined } from '@ant-design/icons';
import { Button as HospyBtn, Icon as IconRenderer } from '@hospy/hospy-ui';
import { AppData } from '@hospy/store';
import {
	Drawer as AntDrawer,
	Button,
	Layout,
	Space,
	Tooltip,
	Typography
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { HospyIcon } from '../../atoms/icons';
import AppSelectApp from '../app-select-app';
import HospyAlertModal from '../hospy-alert-modal';
import { DrawerMenu } from '../nav-bar/nav-bar-menu';
import AppStatus from './app-status';
import UseAppNavBar from './use-app-nav-bar';

const { Header: AntHeader } = Layout;

const AppNavBar = ({
	icon: Icon,
	title,
	titleRight,
	menu,
	hideSelectProperty,
	appData,
	daysToExpire
}: {
	appData: undefined | AppData;
	daysToExpire: number | null;
	icon?: React.ElementType;
	title: string;
	titleRight?: string;
	menu?: React.ReactElement;
	hideSelectProperty?: boolean;
}) => {
	const {
		setOpen,
		user,
		theme,
		buy,
		exitViewUser,
		modalBuyDenied,
		open,
		sProperty,
		setModalBuyDenied
	} = UseAppNavBar({ hideSelectProperty, title });

	const expirationWarning = (
		<ExpirationWarning>
			<ExpirationWarningLeftSide>
				<IconRenderer material="warning" />
				<Typography.Text>
					<FormattedMessage
						id="header.expirationWarning"
						defaultMessage=" {days, plural, =0 {Prueba vencida} one {Tu prueba vence en # día} other {Tu prueba vence en # días}}"
						values={{
							days: daysToExpire
						}}
					/>
				</Typography.Text>
			</ExpirationWarningLeftSide>
			<HospyBtn size="small" onClick={buy}>
				Comprar ahora
			</HospyBtn>
		</ExpirationWarning>
	);

	const showExpirationWarning =
		appData?.pricingModel !== 'free' && daysToExpire !== null;

	return (
		<>
			<Header
				visibleAlert={
					showExpirationWarning &&
					(theme.screens['xs'] ||
						(theme.screens['sm'] && !theme.screens['lg']))
				}
			>
				<HeaderLeft>
					{theme.screens.xs && (
						<Link to="/">
							<HospyIcon style={{ width: 25 }} />
						</Link>
					)}
					<Space>
						{/* {!theme.screens.xs && ( <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }} type="primary" shape="circle" size="small" icon={ <IconRenderer material="arrow_back" style={{ width: 25, height: 25 }} /> } href={`${process.env['NX_APP_START']}?property=${currentProperty}`} /> )} */}
						<SelectPropertyContainer>
							{titleRight && (
								<TitleRight>{titleRight}</TitleRight>
							)}
							{sProperty}
						</SelectPropertyContainer>

						{user?.userViewAdmin && theme.screens['lg'] && (
							<Tooltip
								placement="bottom"
								title="Volver al módulo de usuarios"
							>
								<Button
									onClick={exitViewUser}
									icon={<Eye />}
									size="large"
									type="text"
									shape="circle"
								/>
							</Tooltip>
						)}
					</Space>
				</HeaderLeft>
				<HeaderRight>
					{!theme.screens.xs && showExpirationWarning && (
						<>{expirationWarning}</>
					)}
					{!theme.screens.xs &&
						appData?.maintenance &&
						appData?.maintenanceMessage && <AppStatus />}
					<Title>
						{!theme.screens.xs && Icon && (
							<Icon style={{ marginRight: 10, fontSize: 25 }} />
						)}
						{title}
					</Title>
					<SelectPropertyContainerXs>
						<AppSelectApp title={title} />
						<Button
							type="text"
							icon={<MenuOutlined style={{ fontSize: 30 }} />}
							onClick={() => setOpen(true)}
							size="large"
							style={{ padding: 0, marginLeft: 10 }}
						/>
					</SelectPropertyContainerXs>
					<Drawer
						open={open}
						onClose={() => setOpen(false)}
						closable={false}
						placement="top"
						height={'90%'}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								padding: '0 15px'
							}}
						>
							<Button
								type="text"
								icon={
									<IconRenderer
										style={{ fontSize: 30 }}
										material="close"
										ant
									/>
								}
								onClick={() => setOpen(false)}
							/>
						</div>
						<DrawerMenu
							appName={title}
							onCloseDrawer={() => setOpen(false)}
							hideAddPropertyButton={true}
							menu={menu}
						/>
					</Drawer>
				</HeaderRight>
			</Header>
			{showExpirationWarning &&
				(theme.screens['xs'] ||
					(theme.screens['sm'] && !theme.screens['lg'])) && (
					<>{expirationWarning}</>
				)}
			<HospyAlertModal
				open={modalBuyDenied.open}
				onCancel={() => setModalBuyDenied({ open: false, name: '' })}
				title={
					<FormattedMessage
						id="header.buy-denied.title"
						defaultMessage="Acceso no permitido"
					/>
				}
				subtitle={
					<>
						<FormattedMessage
							id="app-activation-denied.subTitle"
							defaultMessage="No puedes comprar esta aplicación porque no eres el propietario del negocio"
						/>
						<b>{modalBuyDenied.name}</b>
					</>
				}
			/>
		</>
	);
};

const Header = styled(({ visibleAlert, visibleExpirationAlert, ...props }) => (
	<AntHeader {...props} />
))`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid
		${({ theme }) =>
			theme.colors.gray.lighten && theme.colors.gray.lighten['50%']};
	padding: 0rem 1.2rem !important;
	height: auto;
	min-height: 80px;
	position: fixed;
	width: calc(100% - 80px);
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			width: 100%;
			background-color: ${({ theme }) => theme.colors.primary.base};
		`}
	top: 0;
	right: 0;
	z-index: 4;
`;

const Title = styled(Typography.Text)`
	font-size: 15px;
	align-items: center;
	display: flex;
	color: ${({ theme }) => theme.colors.primary.base};
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			color: #fff;
			margin-left: 15px;
		`}
`;

const SelectPropertyContainer = styled.div`
	display: none;
	${({ theme }) =>
		!theme.screens['xs'] &&
		css`
			display: flex;
			gap: 15px;
			align-items: center;
		`}
`;

const SelectPropertyContainerXs = styled.div`
	display: none;
	& .ant-btn {
		color: #fff;
	}
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			display: flex;
			margin-left: 20px;
		`}
`;

const HeaderLeft = styled.div`
	line-height: 1;
`;

const HeaderRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: auto;
	gap: 14px;
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			justify-content: space-between;
			width: 100%;
		`}
`;

const TitleRight = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.large}px;
	color: ${({ theme }) => theme.colors.gray.base};
`;

const Drawer = styled(AntDrawer)`
	.ant-drawer-body {
		padding: 24px 0;
	}
`;

const ExpirationWarning = styled.div`
	margin-left: 24vw;
	height: 50px;
	padding: 0 20px;
	align-items: center;
	gap: 20px;
	display: flex;

	background-color: ${({ theme }) =>
		theme.colors.danger.lighten && theme.colors.danger.lighten['90%']};
	border-radius: 50px;
	svg {
		color: ${({ theme }) => theme.colors.danger.base};
		font-size: ${({ theme }) => theme.title.size.large}px;
	}
	button {
		border: none;
		font-weight: bold;
		height: 40px;
		background-color: inherit;
		display: flex;
		align-items: center;
		border-radius: 50px;
		:hover,
		:active,
		:focus {
			background-color: ${({ theme }) =>
				theme.colors.danger.lighten &&
				theme.colors.danger.lighten['80%']} !important;
		}
	}
	.jopsAt.ant-btn:focus:not(:disabled),
	.jopsAt.ant-btn:hover:not(:disabled) {
		background-color: ${({ theme }) =>
			theme.colors.danger.lighten && theme.colors.danger.lighten['90%']};
	}
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			width: 100%;
			margin: 0;
			justify-content: space-between;
			position: fixed;
			top: 81px;
			border-radius: 0;
			z-index: 2;
			font-size: 12px;
			button {
				font-size: ${theme.text.size.middle}px;
			}
			padding: 0 5px;
			gap: 5px;
		`}
	${({ theme }) =>
		theme.screens['lg'] &&
		css`
			gap: 10px;
			margin-left: 0;
		`}
			
			
	${({ theme }) =>
		theme.screens['sm'] &&
		!theme.screens['lg'] &&
		css`
			width: calc(100% - 200px);
			margin: 100px 10px 0 150px;
			justify-content: space-between;
		`}
`;

const ExpirationWarningLeftSide = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	height: 100%;
	line-height: 22px;
`;

export default AppNavBar;
