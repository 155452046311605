/* eslint-disable @typescript-eslint/no-explicit-any */

export class languageApi {
	static getLanguageMessages = (
		language: string,
		app: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			try {
				import(
					`../../../../../apps/${app}/src/translations/compiled-lang/${language}.json`
				).then((response) => resolve(response.default));
			} catch (error) {
				console.error('Language not implemented');
				resolve({});
			}
		});

	static getLibsLanguageMessages = (
		language: string,
	): Promise<any> =>
		new Promise((resolve, reject) => {
			try {
				import(
					`../../../../languages/compiled-lang/${language}.json`
				).then((response) => resolve(response.default));
			} catch (error) {
				console.error('Language not implemented');
				resolve({});
			}
		});
}
