import { createSlice } from '@reduxjs/toolkit';
import {
	AFIP_INVOICE_DETAIL_SECTIONS,
	afipInitialState
} from './afip.constants';
import {
	afipGetFieldMapping,
	afipGetInvoiceReport,
	afipGetStamp,
	afipGetSummaryReport,
	afipInvoiceCancelStamp,
	afipInvoiceStamp,
	afipUpdateInvoiceStamp
} from './afip.thunks';

export const afipSlice = createSlice({
	name: 'afip',
	initialState: afipInitialState,
	reducers: {
		afipResetInvoiceValidation: (state) => {
			state.invoiceValidation = AFIP_INVOICE_DETAIL_SECTIONS;
		},
		afipUpdateInvoiceValidation: (state, { payload }) => {
			state.invoiceValidation = payload;
		},
		afipSetStamp: (state, { payload }) => {
			state.stamp = payload;
		},
		afipResetInvoiceCancelStamp: (state) => {
			state.cancelStampInvoice = {
				loading: 'idle'
			};
		},
		afipResetUpdateStampInvoice: (state) => {
			state.updateStampInvoice = {
				loading: 'idle',
				error: undefined
			};
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(afipInvoiceStamp.pending, (state) => {
				state.stamp = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(afipInvoiceStamp.rejected, (state, { payload }) => {
				state.stamp = {
					loading: 'failed',
					error: payload,
					data: undefined
				};
			})
			.addCase(afipInvoiceStamp.fulfilled, (state, { payload }) => {
				state.stamp = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(afipUpdateInvoiceStamp.pending, (state) => {
				state.updateStampInvoice = {
					loading: 'pending',
					error: undefined
				};
			})
			.addCase(afipUpdateInvoiceStamp.rejected, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(afipUpdateInvoiceStamp.fulfilled, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'succeeded',
					error: undefined
				};
			});

		builder
			.addCase(afipInvoiceCancelStamp.pending, (state) => {
				state.cancelStampInvoice = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(afipInvoiceCancelStamp.rejected, (state, { payload }) => {
				state.cancelStampInvoice = {
					loading: 'failed',
					error: payload,
					data: undefined
				};
			})
			.addCase(afipInvoiceCancelStamp.fulfilled, (state, { payload }) => {
				state.cancelStampInvoice = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(afipGetStamp.pending, (state) => {
				state.stamp.loading = 'pending';
			})
			.addCase(afipGetStamp.rejected, (state, { payload }) => {
				state.stamp.loading = 'failed';
				state.stamp.error = payload;
			})
			.addCase(afipGetStamp.fulfilled, (state, { payload }) => {
				state.stamp.loading = 'succeeded';
				state.stamp.data = payload;
			});

		builder
			.addCase(afipGetFieldMapping.pending, (state) => {
				state.fieldMapping = {
					loading: 'pending'
				};
			})
			.addCase(afipGetFieldMapping.rejected, (state, { payload }) => {
				state.fieldMapping = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(afipGetFieldMapping.fulfilled, (state, { payload }) => {
				state.fieldMapping = {
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(afipGetInvoiceReport.pending, (state) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'pending'
				};
			})
			.addCase(afipGetInvoiceReport.rejected, (state, { payload }) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(afipGetInvoiceReport.fulfilled, (state, { payload }) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'succeeded',
					data: payload?.paginating
						? [
								...state.invoiceReports.data,
								...(payload?.data || [])
						  ]
						: payload?.data,
					meta: {
						...payload?.meta
					}
				};
			});

		builder
			.addCase(afipGetSummaryReport.pending, (state) => {
				state.summaryReports = {
					...state.summaryReports,
					loading: 'pending'
				};
			})
			.addCase(afipGetSummaryReport.rejected, (state, { payload }) => {
				state.summaryReports = {
					...state.summaryReports,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(afipGetSummaryReport.fulfilled, (state, { payload }) => {
				state.summaryReports = {
					loading: 'succeeded',
					data: payload?.data,
					meta: payload?.meta
				};
			});
	}
});

export const {
	afipResetInvoiceValidation,
	afipUpdateInvoiceValidation,
	afipSetStamp,
	afipResetInvoiceCancelStamp,
	afipResetUpdateStampInvoice
} = afipSlice.actions;
