/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { Fetch } from '../fetch';
import { RequestCreate, RequestFindAll } from '../requests';
import { ResponseFailure, ResponseSuccess } from '../responses';
import { ApiURL } from '../util-url';

interface GetReservationProp {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationCode?: string;
	reservationId?: string;
	filter?: string;
	fields?: string;
}

export const getReservationDataService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationCode,
	reservationId,
	filter,
	fields
}: GetReservationProp): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		if (reservationCode) {
			params.push(`filter={"thirdPartyId":"${reservationCode}"}`);
		}

		if (filter) {
			params.push(`filter=${filter}`);
		}

		if (fields) {
			params.push(`fields=${fields}`);
		}

		fetch(
			ApiURL(
				`/api/pms/reservations${
					reservationId ? '/' + reservationId : ''
				}?${params.join('&')}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReservationStatsService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationCode,
	filter,
	fields
}: GetReservationProp): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		if (reservationCode) {
			params.push(`filter={"thirdPartyId":"${reservationCode}"}`);
		}

		if (filter) {
			params.push(`filter=${filter}`);
		}

		if (fields) {
			params.push(`fields=${fields}`);
		}

		fetch(
			ApiURL(`/api/pms/reservations/stats?${params.join('&')}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const createReservationDataService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL(`/api/pms/reservations`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReservationSyncEnableAccessDate = ({
	propertyId,
	propertyIntegrationId
}: {
	propertyId: string;
	propertyIntegrationId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/pms/reservations/sync/enable-access-date?propertyId=${propertyId}&propertyIntegrationId=${propertyIntegrationId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
interface UpdateReservationDataProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	sendEmail?: string;
	filter?: string;
}
export const updateReservationDataService = (
	id: string,
	props: UpdateReservationDataProps,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const searchParams = new URLSearchParams();
		for (const key in props) {
			if (Object.prototype.hasOwnProperty.call(props, key)) {
				const value = props[key as keyof UpdateReservationDataProps];
				if (value !== undefined) {
					searchParams.append(key, value);
				}
			}
		}

		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};
		fetch(
			ApiURL(`/api/pms/reservations/${id}?${searchParams.toString()}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface ValidateDocumentAirbnbReservationProps {
	appId: string;
	partnerIntegrationId: string;
	propertyId: string;
	resource: string;
	expectedDocument: string;
	name: string;
	contentType: string;
	data: string;
}

export const validateDocumentAirbnbReservationService = (
	data: ValidateDocumentAirbnbReservationProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ ...data, rekognitionVersion: 'v3' })
		};
		const env = process.env['NX_APP_SPA'] || '';
		let url =
			'https://db5c9x2vkc.execute-api.us-east-1.amazonaws.com/dev/api/id-documents/metadata';
		if (/https:\/\/hospy\.co/.test(env)) {
			url =
				'https://sc7em35apc.execute-api.us-east-1.amazonaws.com/prod/api/id-documents/metadata';
		}
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface DeleteReservationDataProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
}
export const deleteReservationDataService = (
	id: string,
	{ propertyId, partnerIntegrationId, appId }: DeleteReservationDataProps
) => {
	return new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const url = ApiURL(
			`/api/pms/reservations/${id}?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&appId=${appId}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
};

export const deleteReservationGuestService = (
	id: string,
	guestId: string,
	{ propertyId, partnerIntegrationId, appId }: DeleteReservationDataProps
) => {
	return new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const url = ApiURL(
			`/api/pms/reservations/${id}/guests/${guestId}?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&appId=${appId}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
};

interface GetReservationByIdProp {
	reservationId: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	responseRaw?: boolean;
}
export const getReservationDataByIdService = ({
	reservationId,
	propertyId,
	partnerIntegrationId,
	appId,
	responseRaw
}: GetReservationByIdProp): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		if (responseRaw != undefined) {
			params.push(`responseRaw=${responseRaw}`);
		}
		fetch(
			ApiURL(
				`/api/pms/reservations/${reservationId}?${params.join('&')}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface GetReportReservationDetailProps {
	format: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	filter: string;
}
export const fetchGetReportReservationDetails = (
	params: GetReportReservationDetailProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('Hospy-idToken')}`
			}
		};

		const queryParams = new URLSearchParams({
			appId: params.appId,
			partnerIntegrationId: params.partnerIntegrationId,
			propertyId: params.propertyId
		} as any).toString();

		const url = ApiURL(
			`/api/pms/reservations/reports/reservation-details/${params.format}?${queryParams}&filter=${params.filter}`
		);

		fetch(url, requestOptions)
			.then((response: any) => {
				if (response.ok) return response.blob();
				throw response;
			})
			.then((blob) => {
				const file = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = file;
				a.download = `Reservaciones-${moment().format(
					'YYYYMMDDHHmmss'
				)}.csv`;
				document.body.appendChild(a);
				a.click();
				a.remove();
				resolve({ status: 'success' });
			})
			.catch((error) => reject(error));
	});

interface PostInitAirbnbReservationProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationId: string;
	filter: string;
	mode?: string;
}

export const postInitReservationService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationId,
	filter
}: PostInitAirbnbReservationProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		if (filter) {
			params.push(`filter=${filter}`);
		}

		fetch(
			ApiURL(
				`/api/pms/reservations/init/${reservationId}?${params.join(
					'&'
				)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface PostUpdateReservationIssues {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationId: string;
	issues: any[];
}

export const postUpdateReservationIssuesService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationId,
	issues
}: PostUpdateReservationIssues): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ issues })
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		fetch(
			ApiURL(
				`/api/pms/reservations/${reservationId}/issues?${params.join(
					'&'
				)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface PostUpdateReservationFeedback {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationId: string;
	feedback: string;
	feedbackComment?: string;
}

export const postUpdateReservationFeedbackService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationId,
	feedback,
	feedbackComment
}: PostUpdateReservationFeedback): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ feedback, feedbackComment })
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		fetch(
			ApiURL(
				`/api/pms/reservations/${reservationId}/feedback?${params.join(
					'&'
				)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getSyncReservationService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationId,
	filter,
	mode
}: PostInitAirbnbReservationProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`,
			`mode=${mode}`
		];

		if (filter) {
			params.push(`filter=${filter}`);
		}

		fetch(
			ApiURL(
				`/api/pms/reservations/sync-partner/${reservationId}?${params.join(
					'&'
				)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindAllReservationNotes = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/pms/reservations/notes', params);

export const RequestImportReservationTotal = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/pms/reservations/imports/:format', params, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
