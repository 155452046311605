import {
	setShowPropertyModal,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Layout, Spin } from 'antd';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import PropertyModal from '../../../components/property-modal/property-modal';
import AppNavBarV2 from '../../organisms/app-nav-bar-v2';
import AppSideBar from '../../organisms/app-side-bar';
import {
	LoadingChangeProperty,
	Main,
	MenuItemContainer,
	UpdateExtraPropertyInfoModal,
	Wp
} from './layout';

interface ContainerProps {
	icon?: React.ElementType;
	children?: React.ReactElement | React.ReactElement[] | undefined;
	title: string;
	titleRight?: string;
	menu?: React.ReactElement;
	pathname?: string | undefined;
	loading?: boolean;
	fullWidth?: boolean;
	hideSelectProperty?: boolean;
	hideAppButton?: boolean;
	mainStyle?: any;
}

export const Container: React.FC<ContainerProps> = ({
	children,
	icon,
	title,
	titleRight,
	menu,
	pathname,
	loading,
	fullWidth,
	hideSelectProperty,
	hideAppButton,
	mainStyle
}) => {
	const theme: any = useTheme();
	const dispatch = useAppDispatch();

	const { showPropertyModal } = useAppSelector(({ start }) => start);
	const { user, propertyId } = useAppSelector(({ user }) => user);
	const { appData } = useAppSelector(({ common }) => common);

	const [daysToExpire, setDaysToExpire] = useState<number | null>(null);

	useEffect(() => {
		if (!appData || !user) return;

		const currentProperty = user.properties?.find(
			(property) => property._id === propertyId
		);

		const currentAppDetails = currentProperty?.apps?.find(
			(app) => app._id === appData._id
		);

		if (currentAppDetails?.subscription === 'trial') {
			const expirationDate = moment(
				moment(currentAppDetails?.expirationDate).format('YYYY-MM-DD')
			).hour(23);

			const today = moment().subtract(1, 'd').hour(0);

			const daysLocal = moment(moment(expirationDate)).diff(today, 'day');

			setDaysToExpire(daysLocal >= 0 ? daysLocal : 0);
		} else {
			setDaysToExpire(null);
		}
	}, [appData, user, user, propertyId]);

	const showExpirationWarning =
		appData?.pricingModel !== 'free' && daysToExpire !== null;

	return (
		<Layout style={{ minHeight: '100vh' }}>
			{/**
			 *
			 *
			 */}
			<AppNavBarV2
				icon={icon}
				title={title}
				titleRight={titleRight}
				hideSelectProperty={hideSelectProperty}
				appData={appData}
				daysToExpire={daysToExpire}
			/>
			{/**
			 *
			 *
			 *
			 */}
			<AppSideBar
				title={title}
				menu={menu}
				hideAppButton={hideAppButton}
			/>

			{/**
			 *
			 *
			 */}
			<Wp showAlert={theme.screens.xs && showExpirationWarning}>
				{loading && (
					<LoadingChangeProperty>
						<Spin size="large" />
					</LoadingChangeProperty>
				)}
				<motion.div
					key={pathname}
					initial={{ opacity: 0, x: 15 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0, x: -15 }}
				>
					<Main
						id="mainContainer"
						fullWidth={fullWidth}
						visibleExpirationAlert={
							appData?.pricingModel !== 'free' &&
							daysToExpire !== null &&
							(theme.screens['xs'] ||
								(theme.screens['sm'] && !theme.screens['lg']))
						}
						style={mainStyle}
					>
						{children}
					</Main>
				</motion.div>
			</Wp>

			<UpdateExtraPropertyInfoModal />
			<PropertyModal
				open={showPropertyModal}
				onClose={() => dispatch(setShowPropertyModal(false))}
			/>
		</Layout>
	);
};
/**
 *
 *
 */
export const Menu = styled.div`
	display: none;

	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			display: flex;
			gap: 0;
			flex-direction: row;
			justify-content: center;
		`}

	${({ theme }) =>
		!theme.screens['xs'] &&
		css`
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 15px;
		`}
`;
/**
 *
 *
 */
export const MenuItem = ({ to, icon: Icon, isSelected, children }: any) => {
	const theme: any = useTheme();
	return (
		<MenuItemContainer isSelected={isSelected}>
			<Link to={to}>
				{Icon && (
					<Icon style={{ fontSize: theme.screens.xs ? 20 : 30 }} />
				)}
				{children}
			</Link>
		</MenuItemContainer>
	);
};
