/* eslint-disable @typescript-eslint/no-explicit-any */
import IconAnt from '@ant-design/icons';
import { Image } from 'antd';
import React from 'react';
import SVG, { ErrorCallback } from 'react-inlinesvg';
import styled from 'styled-components';

export type Material =
	| 'add_photo_alternate'
	| 'visibility_off'
	| 'bookmark'
	| 'stars'
	| 'star'
	| 'publish'
	| 'sell'
	| 'key'
	| 'filter_alt'
	| 'devices'
	| 'account_balance'
	| 'content_paste'
	| 'account_circle'
	| 'add'
	| 'add_circle'
	| 'all_inclusive'
	| 'apps'
	| 'arrow_back'
	| 'arrow_drop_down'
	| 'autorenew'
	| 'brightness_low'
	| 'done'
	| 'done_all'
	| 'cancel'
	| 'check'
	| 'clear_all'
	| 'delete'
	| 'delete_forever'
	| 'directions_walk'
	| 'format_list_bulleted'
	| 'domain'
	| 'edit'
	| 'face'
	| 'filter_list'
	| 'group'
	| 'history'
	| 'home_work'
	| 'house'
	| 'language'
	| 'link'
	| 'login'
	| 'more_vert'
	| 'notification_important'
	| 'open_in_new'
	| 'playlist_add'
	| 'playlist_add_check'
	| 'public'
	| 'rocket_launch'
	| 'search'
	| 'settings_ethernet'
	| 'settings_input_component'
	| 'tune'
	| 'verified'
	| 'verified_user'
	| 'cameraswitch'
	| 'warning'
	| 'calendar_today'
	| 'credit_card'
	| 'keyboard_arrow_down'
	| 'check_circle'
	| 'close'
	| 'admin_panel_settings'
	| 'person_search'
	| 'trending_up'
	| 'groups'
	| 'cleaning_services'
	| 'mood'
	| 'event_available'
	| 'where_to_vote'
	| 'menu_book'
	| 'local_activity'
	| 'star_half'
	| 'insights'
	| 'add_shopping_cart'
	| 'support_agent'
	| 'water_damage'
	| 'delivery_dining'
	| 'local_library'
	| 'lock_open'
	| 'monetization_on'
	| 'visibility'
	| 'arrow_forward_ios'
	| 'link'
	| 'download'
	| 'download_2'
	| 'lock'
	| 'category'
	| 'single_bed'
	| 'receipt'
	| 'send'
	| 'swap_horiz'
	| 'extension'
	| 'play_circle'
	| 'pending_actions'
	| 'upload'
	| 'info'
	| 'backup'
	| 'error'
	| 'subject'
	| 'assignment'
	| 'print'
	| 'settings_input_hdmi'
	| 'north'
	| 'mail'
	| 'share'
	| 'width_wide'
	| 'badge'
	| 'back_hand'
	| 'location'
	| 'crop_rotate'
	| 'chat_info'
	| 'private_connectivity'
	| 'schedule'
	| 'wysiwyg'
	| 'store'
	| 'magic_wand'
	| 'approval'
	| 'redeem'
	| 'task_alt'
	| 'restaurant'
	| 'numbers'
	| 'document_scanner'
	| 'add_box'
	| 'asterisk'
	| 'picture_as_pdf'
	| 'sentiment_satisfied'
	| 'sentiment_neutral'
	| 'sentiment_very_dissatisfied'
	| 'vpn_key'
	| 'price_check'
	| 'checklist_rtl'
	| 'luggage'
	| 'flash_off'
	| 'local_laundry_service'
	| 'sim_card'
	| 'local_taxi'
	| 'light-mode'
	| 'frame_person'
	| 'draw'
	| 'apartment'
	| 'night_shelter'
	| 'escalator_warning'
	| 'favorite'
	| 'ink_eraser'
	| 'videocam'
	| 'flash_on'
	| 'restart_alt'
	| 'photo_camera'
	| 'stat_1'
	| 'videocam_off'
	| 'bolt'
	| 'sensor_occupied'
	| 'description'
	| 'policy'
	| 'forward'
	| 'edit_document'
	| 'sensor_occupied'
	| 'description'
	| 'policy'
	| 'manage_search'
	| 'id_card'
	| 'subtitles'
	| 'account_box';

type MaterialOutlined =
	| 'bookmark'
	| 'star'
	| 'perm_phone_msg'
	| 'check_circle'
	| 'expand_more'
	| 'child_care'
	| 'delete'
	| 'edit'
	| 'help'
	| 'info'
	| 'person'
	| 'verified'
	| 'lock_open'
	| 'sports_esports'
	| 'summarize'
	| 'content_copy'
	| 'check_box'
	| 'check_box_outline'
	| 'arcticle'
	| 'format_bold'
	| 'laptop'
	| 'format_italic'
	| 'attach_money'
	| 'storefront'
	| 'bar_chart'
	| 'sync'
	| 'settings_backup_restore'
	| 'perm_contact_calendar'
	| 'receipt_long'
	| 'save'
	| 'error'
	| 'rotate_left'
	| 'content_copy'
	| 'aspect_ratio'
	| 'schedule'
	| 'block'
	| 'forward_to_inbox'
	| 'whatsapp'
	| 'whatsapp-bold'
	| 'power_settings_new'
	| 'trending_flat'
	| 'ads_click'
	| 'schedule_send'
	| 'notifications'
	| 'add_photo_alternate'
	| 'replay'
	| 'nightlife'
	| 'list_alt'
	| 'warning'
	| 'explore'
	| 'ink_eraser'
	| 'contact_page'
	| 'cancel';

interface IconProps {
	src: string;
	material?: Material;
	materialOutlined?: MaterialOutlined;
	style?: React.CSSProperties;
	onError?: ErrorCallback;
	ant?: boolean;
	circle?: boolean;
}

interface IconProps1 {
	src?: string;
	material: Material;
	materialOutlined?: MaterialOutlined;
	style?: React.CSSProperties;
	onError?: ErrorCallback;
	ant?: boolean;
	circle?: boolean;
}

interface IconProps2 {
	src?: string;
	material?: Material;
	materialOutlined: MaterialOutlined;
	style?: React.CSSProperties;
	onError?: ErrorCallback;
	ant?: boolean;
	circle?: boolean;
	className?: string;
}

export const Icon: React.FC<IconProps | IconProps1 | IconProps2> = ({
	material,
	materialOutlined,
	ant,
	...rest
}): React.ReactElement => {
	const SvgIcon = (props: any) => (
		<Svg
			{...(material
				? { src: `https://cdn.hospy.co/icons-material/${material}.svg` }
				: materialOutlined
				? {
						src: `https://cdn.hospy.co/icons-material/outlined/${materialOutlined}.svg`
				  }
				: { src: '' })}
			{...rest}
			{...props}
		/>
	);

	if (ant) {
		return (
			<IconAnt
				component={(props: any) => <SvgIcon {...props} {...rest} />}
			/>
		);
	}

	if (rest?.src && rest?.circle) {
		return (
			<CircleWhite>
				<Image src={rest.src} />
			</CircleWhite>
		);
	}

	return <SvgIcon />;
};

export default Icon;

const Svg = styled(SVG)`
	user-select: none;
	width: 1em;
	height: 1em;
	display: inline-block;
	flex-shrink: 0;
	fill: currentColor;
`;

const CircleWhite = styled.div`
	border-radius: 50%;
	background: white;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 40px;
	}
`;
