/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchDeleteStampInvoice,
	fetchGetAllInvoice,
	fetchGetAllSummaryReport,
	fetchGetStampInvoice,
	fetchStampInvoice,
	fetchUpdateInvoice,
	getFieldMappingService,
	updateStampInvoiceService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { APPS, PARTNER } from '../common/common.types';
import { InvoiceStamp } from '../partner/accounting/interface';
import { afipBaseRequestProps } from './afip.interface';
import { PartnerRequestProps } from '../common/common.interfaces';
import { afipCancelInvoiceErrorDictionary } from './afip.constants';

export const afipInvoiceStamp = createAsyncThunk(
	'afip/invoice-stamp',
	async (payload: InvoiceStamp, { rejectWithValue }) => {
		const createInvoice = await fetchUpdateInvoice(
			{
				appId: APPS.EFACTURA_AFIP,
				partnerIntegrationId: payload.partnerIntegrationId, // PMS
				partnerIntegrationIdTo: payload.partnerIntegrationIdTo, // finances
				propertyId: payload.propertyId,
				limit: '',
				page: '1',
				filter: ''
			},
			payload.invoiceId,
			{ ...payload.invoice, checked: true }
		);

		if (createInvoice.statusCode !== 200)
			return rejectWithValue(['No se pudo procesar la factura']);

		const response = await fetchStampInvoice(
			{
				...payload,
				partnerIntegrationId: payload.partnerIntegrationIdTo, // finance
				partnerIntegrationIdTo: payload.partnerIntegrationId // PMS
			},
			payload.invoiceId
		);

		if (response.statusCode === 400) {
			return rejectWithValue(response);
		}

		return response.data;
	}
);

export const afipUpdateInvoiceStamp = createAsyncThunk(
	'sat/update-invoice-stamp',
	async (payload: any, { rejectWithValue }) => {
		const createInvoice = await fetchUpdateInvoice(
			{
				appId: APPS.EFACTURA_AFIP,
				partnerIntegrationId: payload.partnerIntegrationId, // PMS
				partnerIntegrationIdTo: payload.partnerIntegrationIdTo, // finances
				propertyId: payload.propertyId,
				limit: '',
				page: '1',
				filter: ''
			},
			payload.invoiceId,
			{ ...payload.invoice, checked: true }
		);

		if (createInvoice.statusCode !== 200) {
			return rejectWithValue(['No se pudo procesar la factura']);
		}

		const response: any = await updateStampInvoiceService(
			{
				...payload,
				partnerIntegrationId: payload.partnerIntegrationIdTo, // finance
				partnerIntegrationIdTo: payload.partnerIntegrationId // PMS
			},
			payload._id
		);

		if (response.statusCode < 200 || response.statusCode > 299)
			return rejectWithValue(
				response.errors.map((error: any) => error.message)
			);

		return response.data;
	}
);

export const afipInvoiceCancelStamp = createAsyncThunk(
	'afip/invoice-cancel-stamp',
	async (payload: any, { rejectWithValue }) => {
		try {
			const response = await fetchDeleteStampInvoice(payload.id, payload);
			if (response.statusCode > 299 || response.statusCode < 200) {
				const error = {
					code: response.code,
					message: response.message,
					statusCode: response.statusCode,
					type: 'text',
					errors: translateCancelError(response.errors)
				};
				return rejectWithValue(error);
			}
			return response.data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(error);
		}
	}
);

export const afipGetStamp = createAsyncThunk(
	'afip/get-stamp',
	async (payload: string, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const response = await fetchGetStampInvoice(
				{
					partnerIntegrationId: PARTNER.FACTURANTE,
					partnerIntegrationIdTo: PARTNER.CLOUDBEDS,
					appId: APPS.EFACTURA_AFIP,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				payload
			);

			if (response.statusCode !== 200)
				return rejectWithValue(response.errors);

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const afipGetFieldMapping = createAsyncThunk(
	'afip/get-field-mapping',
	async (
		{ propertyId, partnerIntegrationId, appId }: afipBaseRequestProps,
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getFieldMappingService(
				`{ propertyId: ObjectId("${propertyId}"), partnerIntegrationId: ObjectId("${partnerIntegrationId}"), appId: ObjectId("${appId}") }`
			);
			if (errors) return rejectWithValue(errors);
			return data[0];
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

interface afipGetInvoiceReportProps extends afipBaseRequestProps {
	limit: number;
	paginating: boolean;
	page: number;
}

export const afipGetInvoiceReport = createAsyncThunk(
	'afip/get-invoice-report',
	async (
		{
			filter,
			propertyId,
			partnerIntegrationId,
			partnerIntegrationIdTo,
			appId,
			limit,
			paginating,
			page
		}: afipGetInvoiceReportProps,
		{ rejectWithValue }
	) => {
		try {
			const { statusCode, data, errors, meta } = await fetchGetAllInvoice(
				{
					filter,
					propertyId,
					partnerIntegrationId,
					partnerIntegrationIdTo,
					appId,
					limit,
					page
				}
			);

			if (statusCode === 200) return { paginating, data, meta };
			if (errors) return rejectWithValue(errors);
			return;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const afipGetSummaryReport = createAsyncThunk(
	'afip/get-summary-report',
	async (
		{
			filter,
			propertyId,
			partnerIntegrationId,
			partnerIntegrationIdTo,
			appId
		}: PartnerRequestProps,
		{ rejectWithValue }
	) => {
		try {
			const { statusCode, data, errors, meta } =
				await fetchGetAllSummaryReport({
					filter,
					propertyId,
					partnerIntegrationId,
					partnerIntegrationIdTo,
					appId
				});

			if (statusCode === 200) return { data, meta };
			if (errors) return rejectWithValue(errors);
			return;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

interface ErrorRaw {
	type: string;
	message: string;
}

const translateCancelError = (errors: ErrorRaw[]) => {
	const translatedErrors: ErrorRaw[] = errors.map((error) => {
		const foundTranslatedError = Object.keys(
			afipCancelInvoiceErrorDictionary
		).find(
			(key) =>
				key.toLocaleLowerCase() ===
				error.message.toLocaleLowerCase().trim()
		);

		return {
			message:
				afipCancelInvoiceErrorDictionary[
					foundTranslatedError as keyof typeof afipCancelInvoiceErrorDictionary
				] || error.message,
			type: error.type
		};
	});
	return translatedErrors;
};
