import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { adminBillingSlice } from './admin-billing/slice';
import { afipSlice } from './afip/afip.slice';
import { airbnbReservationSlice } from './airbnb-reservation/airbnb-reservation.slice';
import { idDocumentSlice } from './id-document/id-document.slice';
import { AdminLeadsSlice } from './admin-leads/slice';
import { analyticsSlice } from './analytics/slice';
import { appSlice } from './app/slice';
import { checkInHostSlice } from './checkin-host/slice';
import { slice as checkinSlice } from './checkin/slice';
import {
	commonSlice,
	customFormSlice,
	logsSlice,
	playlistsSlice,
	schedulesSlice
} from './common';
import { creditSlice } from './credits/credits.slice';
import { guestReportSlice } from './guest-report/slice';
import { guestSlice } from './guest/guestSlice';
import { languageSlice } from './language/languageSlice';
import { onboardingSlice } from './onboarding/onboarding.slice';

import {
	partnerAccountingSlice,
	partnerCompaniesSlice,
	partnerCurrencySlice,
	partnerCustomersSlice,
	partnerListingsSlice,
	partnerReservationSlice,
	partnerWarehouseSlice
} from './partner';
import { paymentCollectSlice } from './payment-collect/paymentCollectSlice';
import { propertySlice } from './property/slice';
import { ReservationSlice as reservationSlice } from './reservation/slice';
import { satSlice } from './sat/sat.slice';
import { siigoConnectSlice } from './siigo-connect/slice';
import { spaSlice } from './spa/spaSlice';
import { startSlice } from './start/start.slice';
import { subscriptionSlice } from './subscription/subscription.slice';
import { subscriptionsSlice } from './subscriptions/slice';
import { thirdPartyCloudbeds } from './third-party-cloudbeds/slice';
import { ThirdPartyReservationsSlice } from './third-party-reservations/slice';
import { thirdPartyRntReportsSlice } from './third-party-rnt-reports/slice';
import { traSireSlice } from './tra-sire/tra-sire.slice';
import { traSlice } from './tra/tra.slice';
import { userSlice } from './user/user.slice';
import { workTeamSlice } from './workteam/slice';
import { hubspotSlice } from './hubspot-connect/slice';
import { termsAndConditionsSlice } from './common/terms-and-conditions/slice';

export const store = configureStore({
	reducer: {
		user: userSlice.reducer,
		guest: guestSlice.reducer,
		checkin: checkinSlice.reducer,
		start: startSlice.reducer,
		reservation: reservationSlice.reducer,
		spa: spaSlice.reducer,
		paymentCollect: paymentCollectSlice.reducer,
		tra: traSlice.reducer,
		common: commonSlice.reducer,
		thirdPartyReservations: ThirdPartyReservationsSlice.reducer,
		thirdPartyRntReports: thirdPartyRntReportsSlice.reducer,
		thirdPartyCloudbeds: thirdPartyCloudbeds.reducer,
		language: languageSlice.reducer,
		guestReport: guestReportSlice.reducer,
		workteam: workTeamSlice.reducer,
		app: appSlice.reducer,
		adminBilling: adminBillingSlice.reducer,
		property: propertySlice.reducer,
		subscriptions: subscriptionsSlice.reducer,
		subscription: subscriptionSlice.reducer,
		traSire: traSireSlice.reducer,
		siigoConnect: siigoConnectSlice.reducer,
		airbnbReservation: airbnbReservationSlice.reducer,
		partnerReservation: partnerReservationSlice.reducer,
		partnerCustomer: partnerCustomersSlice.reducer,
		partnerCompanies: partnerCompaniesSlice.reducer,
		partnerCurrency: partnerCurrencySlice.reducer,
		partnerAccounting: partnerAccountingSlice.reducer,
		partnerWarehouse: partnerWarehouseSlice.reducer,
		partnerListings: partnerListingsSlice.reducer,
		logs: logsSlice.reducer,
		onboarding: onboardingSlice.reducer,
		sat: satSlice.reducer,
		hubspotConnect: hubspotSlice.reducer,
		afip: afipSlice.reducer,
		analytics: analyticsSlice.reducer,
		idDocument: idDocumentSlice.reducer,
		credits: creditSlice.reducer,
		checkInHost: checkInHostSlice.reducer,
		schedules: schedulesSlice.reducer,
		termsAndConditions: termsAndConditionsSlice.reducer,
		customForms: customFormSlice.reducer,
		adminLeads: AdminLeadsSlice.reducer,
		playlists: playlistsSlice.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		})
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
