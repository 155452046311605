/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { PARTNER } from '../common/common.types';
import { initialState } from './tra.constants';
import { Settings } from './tra.interfaces';
import {
	createListing,
	createSireTraSettings,
	deleteSireTraSettings,
	getAllListings,
	getCloudbedsRooms,
	getFieldMapping,
	getIotLockFieldMapping,
	getListings,
	getRefreshFieldMapping,
	getTraSireSettings,
	getTraSireSettingsV2,
	putSireTraSettings,
	removeListing,
	removePropertyIntegration,
	rntValidateCredentials,
	ThunkPatchListing,
	traEditListing,
	updateFieldMapping,
	updateIotLockFieldMapping
} from './tra.thunks';

export const traSlice = createSlice({
	name: 'tra',
	initialState,
	reducers: {
		setRntValidateCredentialsIdle: (state) => {
			state.rntValidate = {
				...state.rntValidate,
				loading: 'idle'
			};
		},
		setSireTraSettingsIdle: (state) => {
			state.sireTraSettings = {
				...state.sireTraSettings,
				loading: 'idle'
			};
		},
		setRntMode2: (state, { payload }) => {
			state.rntValidate = {
				loading: 'succeeded',
				data: payload,
				error: ''
			};
		},
		resetRemovePropertyIntegrationLoading: (state) => {
			state.deletePropertyIntegration.loading = 'idle';
		},
		resetPutSireTraSettings: (state) => {
			state.updateSireTraSettings = {
				...state.updateSireTraSettings,
				loading: 'idle'
			};
			state.createSireTraSettingsData.loading = 'idle';
		},
		resetDeleteSireTraSettings: (state) => {
			state.removeSireTraSettings = {
				loading: 'idle',
				error: ''
			};
		},
		resetRefreshFieldMapping: (state) => {
			state.refreshFieldMapping = {
				loading: 'idle',
				error: ''
			};
		},
		resetSettings: (state) => {
			state.settings = {
				...state.settings,
				data: undefined
			};
		},
		setSettings: (state, action: PayloadAction<Settings>) => {
			state.settings = action.payload;
		},
		resetTraEditListing: (state) => {
			state.editListing = {
				loading: 'idle',
				error: ''
			};
		},
		resetTraDeleteListing: (state) => {
			state.deleteListingState = {
				loading: 'idle',
				error: ''
			};
		},
		setEditLocalListing: (state, action) => {
			state.allListings.data = [
				...(state.allListings.data || []).map((e) =>
					e._id === action.payload._id ? action.payload : e
				)
			];
			state.listings.data = [
				...(state.listings.data || []).map((e) =>
					e._id === action.payload._id ? action.payload : e
				)
			];
		},
		addLocalListing: (state, action) => {
			if (state.allListings.data) {
				state.allListings.data?.unshift(action.payload);
			}
			if (state.listings.data) {
				state.listings.data?.unshift(action.payload);
			}
		},
		traSetFiledMapping: (state, action) => {
			state.fieldMapping.data = action.payload;
		},
		setListingConfigTimesAdd: (state, action) => {
			if (state.listings.data) {
				state.listings.data = state.listings.data.map((e) => ({
					...e,
					arrivalTime: e.arrivalTime
						? e.arrivalTime
						: action.payload.arrivalTime,
					departureTime: e.departureTime
						? e.departureTime
						: action.payload.departureTime
				}));
			}
			if (state.allListings.data) {
				state.allListings.data = state.allListings.data.map((e) => ({
					...e,
					arrivalTime: e.arrivalTime
						? e.arrivalTime
						: action.payload.arrivalTime,
					departureTime: e.departureTime
						? e.departureTime
						: action.payload.departureTime
				}));
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				getTraSireSettings.fulfilled,
				(action, { payload: data }) => {
					action.settings = {
						loading: 'succeeded',
						error: '',
						data
					};
				}
			)
			.addCase(getTraSireSettings.pending, (action) => {
				action.settings.loading = 'pending';
			})
			.addCase(getTraSireSettings.rejected, (action, { payload }) => {
				action.settings = {
					loading: 'failed',
					error: '',
					data: undefined
				};
			});

		builder
			.addCase(
				getTraSireSettingsV2.fulfilled,
				(action, { payload: data }) => {
					action.settingsV2.loading = 'succeeded';
					action.settingsV2.error = '';
					action.settings.data = data;
				}
			)
			.addCase(getTraSireSettingsV2.pending, (action) => {
				action.settingsV2.loading = 'pending';
				action.settingsV2.error = '';
			})
			.addCase(getTraSireSettingsV2.rejected, (action, { payload }) => {
				action.settingsV2.loading = 'failed';
				action.settingsV2.error = String(payload);
			});

		builder
			.addCase(rntValidateCredentials.pending, (action, { payload }) => {
				action.rntValidate = {
					loading: 'pending',
					error: '',
					data: undefined
				};
			})
			.addCase(rntValidateCredentials.rejected, (action, { payload }) => {
				action.rntValidate = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(
				rntValidateCredentials.fulfilled,
				(action, { payload }) => {
					action.rntValidate = {
						loading: 'succeeded',
						error: '',
						data: payload
					};
				}
			);

		builder
			.addCase(createSireTraSettings.pending, (action, { payload }) => {
				action.createSireTraSettingsData.loading = 'pending';

				action.sireTraSettings = {
					loading: 'pending',
					error: '',
					data: undefined
				};
			})
			.addCase(createSireTraSettings.rejected, (action, { payload }) => {
				action.createSireTraSettingsData.loading = 'failed';
				action.createSireTraSettingsData.error = String(payload);

				action.sireTraSettings = {
					loading: 'failed',
					error: '',
					data: undefined
				};
			})
			.addCase(createSireTraSettings.fulfilled, (action, { payload }) => {
				action.createSireTraSettingsData.loading = 'succeeded';
				action.sireTraSettings = {
					loading: 'succeeded',
					error: '',
					data: payload
				};
				action.settings = {
					...action.settings,
					data: payload
				};
			});

		builder
			.addCase(getCloudbedsRooms.pending, (action, { payload }) => {
				action.cloudbedsGetRooms = {
					data: undefined,
					loading: 'pending',
					error: ''
				};
			})
			.addCase(getCloudbedsRooms.rejected, (action, { payload }) => {
				action.cloudbedsGetRooms = {
					data: undefined,
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(getCloudbedsRooms.fulfilled, (action, { payload }) => {
				console.log('getCloudbedsRooms.fulfilled: ', { payload });

				action.cloudbedsGetRooms = {
					data: payload,
					loading: 'succeeded',
					error: ''
				};
			});

		builder
			.addCase(
				removePropertyIntegration.pending,
				(action, { payload }) => {
					action.deletePropertyIntegration = {
						loading: 'pending',
						error: '',
						data: undefined
					};
				}
			)
			.addCase(
				removePropertyIntegration.rejected,
				(action, { payload }) => {
					action.deletePropertyIntegration = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				removePropertyIntegration.fulfilled,
				(action, { payload }) => {
					action.deletePropertyIntegration = {
						loading: 'succeeded',
						error: '',
						data: payload
					};
				}
			);

		builder
			.addCase(putSireTraSettings.fulfilled, (action, { payload }) => {
				action.updateSireTraSettings = {
					error: '',
					loading: 'succeeded'
				};
				action.settings.data = payload;
			})
			.addCase(putSireTraSettings.pending, (action, { payload }) => {
				action.updateSireTraSettings = {
					error: '',
					loading: 'pending'
				};
			})
			.addCase(putSireTraSettings.rejected, (action, { payload }) => {
				action.updateSireTraSettings = {
					error: String(payload),
					loading: 'failed'
				};
			});

		builder
			.addCase(deleteSireTraSettings.fulfilled, (action, { payload }) => {
				action.removeSireTraSettings = {
					error: '',
					loading: 'succeeded'
				};
			})
			.addCase(deleteSireTraSettings.rejected, (action, { payload }) => {
				action.removeSireTraSettings = {
					error: String(payload),
					loading: 'failed'
				};
			})
			.addCase(deleteSireTraSettings.pending, (action, { payload }) => {
				action.removeSireTraSettings = {
					error: '',
					loading: 'pending'
				};
			});

		builder
			.addCase(getFieldMapping.pending, (action, { payload }) => {
				action.fieldMapping = {
					loading: 'pending',
					data: undefined,
					error: ''
				};
			})
			.addCase(getFieldMapping.rejected, (action, { payload }) => {
				action.fieldMapping = {
					loading: 'failed',
					data: undefined,
					error: String(payload)
				};
			})
			.addCase(getFieldMapping.fulfilled, (action, { payload }) => {
				action.fieldMapping = {
					loading: 'succeeded',
					data: payload,
					error: ''
				};
			});

		builder
			.addCase(
				getRefreshFieldMapping.fulfilled,
				(action, { payload }) => {
					action.refreshFieldMapping = {
						error: '',
						loading: 'succeeded'
					};
					action.fieldMapping = {
						...action.fieldMapping,
						data: payload
					};
				}
			)
			.addCase(getRefreshFieldMapping.pending, (action, { payload }) => {
				action.refreshFieldMapping = {
					error: '',
					loading: 'pending'
				};
			})
			.addCase(getRefreshFieldMapping.rejected, (action, { payload }) => {
				action.refreshFieldMapping = {
					error: String(payload),
					loading: 'failed'
				};
			});

		builder
			.addCase(getListings.pending, (action, { payload }) => {
				action.listings = {
					loading: 'pending',
					data: action.listings?.data,
					error: '',
					meta: action.listings?.meta
				};
			})
			.addCase(getListings.rejected, (action, { payload }) => {
				action.listings = {
					loading: 'failed',
					data: undefined,
					error: String(payload),
					meta: undefined
				};
			})
			.addCase(getListings.fulfilled, (action, { payload }) => {
				let _listings = [...(action.listings?.data || [])];
				if (payload?.meta?.currentPage > 1) {
					_listings = [..._listings, ...payload.data];
				} else {
					_listings = payload.data;
				}

				action.listings = {
					loading: 'succeeded',
					data: _listings,
					error: '',
					meta: payload.meta
				};
			});

		builder
			.addCase(createListing.pending, (action, { payload }) => {
				action.createListing = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(createListing.rejected, (action, { payload }) => {
				action.createListing = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(createListing.fulfilled, (action, { payload }) => {
				action.createListing = {
					loading: 'succeeded',
					error: ''
				};

				if (action.listings.data) {
					action.listings = {
						...action.listings,
						data: [payload, ...action.listings.data]
					};
				} else {
					action.listings = {
						...action.listings,
						data: [payload]
					};
				}
			});

		builder
			.addCase(traEditListing.pending, (action, { payload }) => {
				action.editListing = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(traEditListing.rejected, (action, { payload }) => {
				action.editListing = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(traEditListing.fulfilled, (action, { payload }) => {
				action.editListing = {
					loading: 'succeeded',
					error: ''
				};

				if (action.listings.data) {
					action.listings.data = produce(
						action.listings.data,
						(draft: any): any => {
							const index = draft?.findIndex(
								(e: any) => e._id === payload._id
							);
							if (index > -1) draft[index] = payload;
						}
					);
				}

				if (action.allListings.data) {
					action.allListings.data = produce(
						action.allListings.data,
						(draft: any): any => {
							const index = draft?.findIndex(
								(e: any) => e._id === payload._id
							);
							if (index > -1) draft[index] = payload;
						}
					);
				}
			});

		builder
			.addCase(ThunkPatchListing.pending, (action, { payload }) => {
				action.editListing = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(ThunkPatchListing.rejected, (action, { payload }) => {
				action.editListing = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(
				ThunkPatchListing.fulfilled,
				(action, { payload }: any) => {
					action.editListing = {
						loading: 'succeeded',
						error: ''
					};

					if (action.listings.data) {
						action.listings.data = produce(
							action.listings.data,
							(draft: any): any => {
								const index = draft?.findIndex(
									(e: any) => e._id === payload.data._id
								);
								if (index > -1) draft[index] = payload.data;
							}
						);
					}
				}
			);

		builder
			.addCase(removeListing.pending, (action) => {
				action.deleteListingState = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(removeListing.rejected, (action, { payload }) => {
				action.deleteListingState = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(removeListing.fulfilled, (action, { payload }) => {
				action.deleteListingState = {
					loading: 'succeeded',
					error: ''
				};
				let _listings = current(action.listings);
				if (_listings.data) {
					_listings = {
						..._listings,
						data: _listings.data?.filter((e) => e._id !== payload)
					};
				}
				action.listings = _listings;
			});

		builder
			.addCase(getAllListings.pending, (action) => {
				action.allListings.loading = 'pending';
			})
			.addCase(getAllListings.rejected, (action, { payload }) => {
				action.allListings.loading = 'failed';
				action.allListings.error = payload;
			})
			.addCase(getAllListings.fulfilled, (action, { payload }) => {
				action.allListings = {
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(updateFieldMapping.pending, (state) => {
				state.updatedFieldMapping.loading = 'pending';
			})
			.addCase(updateFieldMapping.fulfilled, (state, { payload }) => {
				if (state.fieldMapping.data?.fields) {
					let fields = current(state.fieldMapping?.data.fields);
					if (payload.updateMode === 'pull') {
						// TODO: validar en la app de Colombia sire TRA
						fields = fields.filter(
							(e) => !(payload.items || []).includes(e._id)
						);
					} else if (payload.updateMode === 'push') {
						fields = payload.items.fields;
					} else {
						if ((payload.items || [])[0]?._parentId) {
							fields = fields.map((e) => {
								const el = (payload.items || []).find(
									(i: any) => i._parentId === e._id
								);

								if (el) {
									const newExtras = e.extras?.map(
										(i: any) => {
											if (el._id === i._id) {
												delete el._parentId;
												return { ...i, ...el };
											}

											return { ...i };
										}
									);
									return { ...e, ...el, extras: newExtras };
								}
								return { ...e };
							});
						} else {
							fields = fields.map((e) => {
								const el = (payload.items || []).find(
									(i: any) => i._id === e._id
								);

								if (el) {
									return { ...e, ...el };
								}
								return { ...e };
							});
						}
					}
					state.fieldMapping.data.fields = fields;
					state.updatedFieldMapping.loading = 'succeeded';
				}
			})
			.addCase(updateFieldMapping.rejected, (state, { payload }) => {
				state.updatedFieldMapping.loading = 'failed';
				state.updatedFieldMapping.error = payload as string;
			});

		builder
			.addCase(getIotLockFieldMapping.pending, (state, action) => {
				const { partnerIntegrationId } = action.meta.arg;

				if (partnerIntegrationId === PARTNER.TTLOCK) {
					state.ttlockFieldMapping = {
						loading: 'pending',
						data: undefined,
						error: ''
					};
				}
				if (partnerIntegrationId === PARTNER.FLEXIPASS) {
					state.flexipassFieldMapping = {
						loading: 'pending',
						data: undefined,
						error: ''
					};
				}
			})
			.addCase(getIotLockFieldMapping.rejected, (state, action) => {
				const { partnerIntegrationId } = action.meta.arg;

				if (partnerIntegrationId === PARTNER.TTLOCK) {
					state.ttlockFieldMapping = {
						loading: 'failed',
						data: undefined,
						error: String(action.payload)
					};
				}
				if (partnerIntegrationId === PARTNER.FLEXIPASS) {
					state.flexipassFieldMapping = {
						loading: 'failed',
						data: undefined,
						error: String(action.payload)
					};
				}
			})
			.addCase(getIotLockFieldMapping.fulfilled, (state, action) => {
				const { partnerIntegrationId } = action.meta.arg;
				if (partnerIntegrationId === PARTNER.TTLOCK) {
					state.ttlockFieldMapping = {
						loading: 'succeeded',
						data: action.payload,
						error: ''
					};
				}
				if (partnerIntegrationId === PARTNER.FLEXIPASS) {
					state.flexipassFieldMapping = {
						loading: 'succeeded',
						data: action.payload,
						error: ''
					};
				}
			});

		builder
			.addCase(updateIotLockFieldMapping.pending, (state, action) => {
				const { id } = action.meta.arg;
				if (id === state.ttlockFieldMapping.data?._id) {
					state.updatedIotLockFieldMapping.loading = 'pending';
				}
				if (id === state.flexipassFieldMapping.data?._id) {
					state.updatedIotLockFieldMapping.loading = 'pending';
				}
			})
			.addCase(
				updateIotLockFieldMapping.fulfilled,
				(state, { meta, payload }) => {
					const { id } = meta.arg;

					let fields;
					if (id === state?.ttlockFieldMapping?.data?._id) {
						fields = current(state.ttlockFieldMapping?.data.fields);
					}

					if (id === state?.flexipassFieldMapping?.data?._id) {
						fields = current(
							state.flexipassFieldMapping?.data.fields
						);
					}

					if (fields) {
						if (payload.updateMode === 'pull') {
							// TODO: validar en la app de Colombia sire TRA
							fields = fields.filter(
								(e) =>
									!(payload.items || []).some(
										(item) => item._id === e._id
									)
							);
						} else if (payload.updateMode === 'push') {
							fields = payload.items.fields;
						} else {
							if ((payload.items || [])[0]?._parentId) {
								fields = fields.map((e) => {
									const el = (payload.items || []).find(
										(i: any) => i._parentId === e._id
									);

									if (el) {
										const newExtras = e.extras?.map(
											(i: any) => {
												if (el._id === i._id) {
													delete el._parentId;
													return { ...i, ...el };
												}

												return { ...i };
											}
										);
										return {
											...e,
											...el,
											extras: newExtras
										};
									}
									return { ...e };
								});
							} else {
								fields = fields.map((e) => {
									const el = (payload.items || []).find(
										(i: any) => i._id === e._id
									);

									if (el) {
										return { ...e, ...el };
									}
									return { ...e };
								});
							}
						}

						if (id === state?.ttlockFieldMapping?.data?._id) {
							state.ttlockFieldMapping.data.fields = fields;
							state.updatedIotLockFieldMapping.loading =
								'succeeded';
						}

						if (id === state?.flexipassFieldMapping?.data?._id) {
							state.flexipassFieldMapping.data.fields = fields;
							state.updatedIotLockFieldMapping.loading =
								'succeeded';
						}
					}
				}
			)
			.addCase(updateIotLockFieldMapping.rejected, (state, action) => {
				state.updatedIotLockFieldMapping.loading = 'failed';
				state.updatedIotLockFieldMapping.error =
					action.payload as string;
			});
	}
});

export const {
	addLocalListing,
	setEditLocalListing,
	setRntValidateCredentialsIdle,
	setSireTraSettingsIdle,
	setRntMode2,
	resetRemovePropertyIntegrationLoading,
	resetPutSireTraSettings,
	resetDeleteSireTraSettings,
	resetRefreshFieldMapping,
	resetSettings,
	setSettings,
	resetTraEditListing,
	resetTraDeleteListing,
	traSetFiledMapping,
	setListingConfigTimesAdd
} = traSlice.actions;
