/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Async,
	IInternalAccount,
	IReservationInvoice,
	PARTNER
} from '@hospy/store';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import InvoiceFilterContext from '../../../context/index';
import InvoiceFilterForm from './components/invoice-filter-form';
import { useInvoices } from './hooks/use-invoice';
import { useInvoiceListFilters } from './hooks/use-invoice-filters';
import MainInvoice from './components/main-invoice';
import HeaderInvoiceList from './components/header';
import { Checkbox, Tooltip } from 'antd';
import { Button } from '../../../../../../../../hospy-ui/src';
import StampBulkModal from './components/stamp-bulk-modal';
import FirstTimeTaxModal from './components/first-time-tax';
import FirstTimePaymentWayModal from './components/first-time-payment-way';

export interface ListInvoicesReservationProps {
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	reservations: Async;
	getReservations: (payload: any) => any;
	setReservations: (payload: any) => any;
	actionCloseDetail?: (action: any) => any;
	hiddenButtonScheduleInvoice?: boolean;
	showCreateInvoiceButton?: boolean;
	currencyDisplay?: string;
	detailChildren?: (
		selectedReservation: any,
		closeInvoiceDetail: any
	) => React.ReactElement | React.ReactElement[] | undefined;
	screen: string;
	selectScreen: (screen: string) => void;
	selectSource: (
		source: IReservationInvoice | IInternalAccount,
		index: number
	) => void;
	selectedSource: IReservationInvoice | IInternalAccount | null;
	multipleInvoices: boolean | undefined;
}

export const ListInvoicesReservation = (
	props: ListInvoicesReservationProps
) => {
	const {
		loading,
		fieldMappingLoading,
		reservations,
		invoice,
		invoiceLoading,
		reservationToBulk,
		allReservationsToBulk,
		stampBulkModal,
		checkReservationDisabled,

		closeInvoiceDetail,
		actionCloseDetail,
		onSelectAllToBulk,
		handleStampBulk,
		closeStampBulkModal,

		firstTimeTaxRequired,
		closeFirstTimeTaxModal,
		onFinishTaxModal,

		firstTimePaymentWayRequired,
		closeFirstTimePaymentWayModal,
		onFinishPaymentWayModal,

		stampBulkLoading
	} = useInvoices(props);

	const {
		updateSelectedDates,
		updateFilterField,
		refresh,
		searchByValue,
		searchValue,
		page,
		updatePage,
		selectedAdditionalFilters,
		justReservationsInProcess,
		swipeJustInvoiceInProcess,
		updateAdditionalFilters,
		selectedFilterField,
		selectedDates,
		changeListingSelected,
		listing
	} = useInvoiceListFilters({ ...props, closeInvoiceDetail });

	return (
		<InvoiceFilterContext.Provider
			value={{
				partnerIntegrationId: props.partnerIntegrationId,
				partnerIntegrationIdTo: props.partnerIntegrationIdTo,
				updateAdditionalFilters,
				updateSelectedDates,
				updateFilterField,
				selectedAdditionalFilters,
				justReservationsInProcess,
				actionCloseDetail,
				refresh,
				searchByValue,
				searchValue,
				loading,
				selectedSource: props.selectedSource,
				page,
				updatePage,
				inProcessOrFailureCounter:
					props.reservations.data?.meta?.inProcessOrFailureCounter ||
					0,
				swipeJustInvoiceInProcess,
				filterField: selectedFilterField,
				dates: selectedDates,
				changeListingSelected,
				listing
			}}
		>
			<InvoiceReservationWrapper>
				<HeaderWrapper>
					{!props.selectedSource && (
						<InvoiceFilterForm
							showCreateInvoiceButton={
								props.showCreateInvoiceButton
							}
						/>
					)}
					<HeaderInvoiceList
						count={props.reservations.data?.meta?.count || 0}
						isReservationSelected={!!props.selectedSource}
					/>
				</HeaderWrapper>

				{reservations && (
					<HeaderStampList
						hasBulkOption={
							props.partnerIntegrationId === PARTNER.AIRBNB
						}
						isSelectedSource={!!props.selectedSource}
					>
						<div>
							<PaginationSummary>
								<FormattedMessage
									id="partner.invoice.counter"
									defaultMessage="{reservations, plural, =0 {No se encontraron reservas} one {Se encontró una reserva} other {Mostrando # reservas} }"
									values={{
										reservations: reservations?.length || 0
									}}
								/>
							</PaginationSummary>
							{!props.selectedSource &&
								props.partnerIntegrationId ===
									PARTNER.AIRBNB && (
									<Tooltip
										title={
											checkReservationDisabled
												? checkReservationDisabled
												: undefined
										}
									>
										<CheckboxCustom
											disabled={
												reservations.length === 0 ||
												checkReservationDisabled !==
													undefined
											}
											checked={
												reservations.length > 0 &&
												allReservationsToBulk
											}
											onChange={(e) =>
												onSelectAllToBulk(
													e.target.checked
												)
											}
										>
											{allReservationsToBulk
												? 'Desmarcar todas las reservas sin facturar'
												: 'Marcar todas las reservas sin facturar'}
										</CheckboxCustom>
									</Tooltip>
								)}
						</div>
						<div>
							{reservationToBulk.length >= 2 &&
								!props.selectedSource && (
									<Button
										loading={
											fieldMappingLoading === 'pending' ||
											stampBulkLoading === 'pending'
										}
										onClick={() => handleStampBulk()}
										type="primary"
										green
										size="large"
									>
										<FormattedMessage
											id="partner.invoice.reservationsToBulk.counter"
											defaultMessage="Emitir {reservationsToBulk, plural, =0 {} one {factura} other {# facturas} }"
											values={{
												reservationsToBulk:
													reservationToBulk.length
											}}
										/>
									</Button>
								)}
						</div>
					</HeaderStampList>
				)}
				<MainInvoice
					invoice={invoice}
					invoiceLoading={invoiceLoading}
					reservations={reservations}
					selectReservation={props.selectSource}
					multipleInvoices={props.multipleInvoices}
				/>
			</InvoiceReservationWrapper>
			{stampBulkModal && (
				<StampBulkModal
					open={stampBulkModal}
					onClose={closeStampBulkModal}
					refresh={refresh}
				/>
			)}
			{firstTimeTaxRequired && (
				<FirstTimeTaxModal
					closable={true}
					onClose={closeFirstTimeTaxModal}
					open={firstTimeTaxRequired}
					onFinish={onFinishTaxModal}
				/>
			)}
			{firstTimePaymentWayRequired && (
				<FirstTimePaymentWayModal
					closable={true}
					onClose={closeFirstTimePaymentWayModal}
					open={firstTimePaymentWayRequired}
					onFinish={onFinishPaymentWayModal}
				/>
			)}
		</InvoiceFilterContext.Provider>
	);
};

const InvoiceReservationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	.ant-form-item {
		margin: 0;
	}

	.ant-picker-suffix {
		color: black;
	}

	p {
		margin-bottom: 0;
	}
`;

const HeaderStampList = styled(
	({ isSelectedSource, hasBulkOption, ...props }) => <div {...props} />
)`
	display: flex;
	justify-content: space-between;
	${({ isSelectedSource, hasBulkOption }) =>
		!isSelectedSource &&
		hasBulkOption &&
		css`
			min-height: 70px;
		`}
	align-items: center;
`;

const CheckboxCustom = styled(Checkbox)`
	background-color: ${({ theme }) => theme.colors.gray.lighten?.['80%']};
	padding-block: 8px;
	padding-inline: 12px;
`;

const PaginationSummary = styled.div``;
