import { InvoiceDetailSection } from '../siigo-connect/interface';
import { AfipInitialState } from './afip.interface';

export const AFIP_INVOICE_DETAIL_SECTIONS: InvoiceDetailSection[] = [
	{
		key: 'invoiceType',
		hasError: false,
		fields: {
			invoiceType: { hasError: false },
			invoiceTypeT: { hasError: false }
		}
	},
	{
		key: 'customer',
		hasError: false,
		fields: {
			name: {
				hasError: false
			},
			lastName: {
				hasError: false
			},
			companyName: {
				hasError: false
			},
			documentTypeId: {
				hasError: false
			},
			documentNumber: {
				hasError: false
			},
			taxTreatment: {
				hasError: false
			},
			countryId: {
				hasError: false
			},
			address: {
				hasError: false
			},
			stateId: {
				hasError: false
			},
			zipCode: {
				hasError: false
			}
		}
	},
	{
		key: 'items',
		hasError: false,
		fields: {}
	},
	{
		key: 'payments',
		hasError: false,
		fields: {}
	}
];

export const AFIP_CREATE_CUSTOMER_FIELD_MESSAGE = {
	invoiceType: { key: 'invoiceType', name: 'Tipo de factura' },
	invoiceTypeT: { key: 'invoiceTypeT', name: 'Tipo de factura turismo' },
	name: { key: 'name', name: 'Nombres' },
	lastName: { key: 'name', name: 'Apellidos' },
	companyName: {
		key: 'companyName',
		name: 'Nombre o razón social del contribuyente'
	},
	documentTypeId: { key: 'documentTypeId', name: 'Tipo de documento' },
	documentNumber: { key: 'documentNumber', name: 'Número de documento' },
	countryId: { key: 'country', name: 'País' },
	address: { key: 'address', name: 'Dirección' },
	stateId: { key: 'stateId', name: 'Provincia' },
	taxTreatment: { key: 'taxTreatment', name: 'Tratamiento impositivo' },
	zipCode: { key: 'zipCode', name: 'Código postal' }
};

export const afipInitialState: AfipInitialState = {
	invoiceValidation: AFIP_INVOICE_DETAIL_SECTIONS,
	stamp: {
		loading: 'idle',
		error: undefined,
		data: undefined
	},
	updateStampInvoice: {
		loading: 'idle'
	},
	cancelStampInvoice: {
		loading: 'idle'
	},
	fieldMapping: {
		loading: 'idle'
	},
	invoiceReports: {
		loading: 'idle'
	},
	summaryReports: {
		loading: 'idle'
	}
};

export const afipCancelInvoiceErrorDictionary = {
	errorInterno: 'Error en la comunicación con Facturante.'
};
