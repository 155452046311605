/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	RequestFindAllListings
} from '@hospy/util-api/service/partner';
import {
	RequestCreate,
	RequestFindAll,
	RequestRemove,
	RequestUpdate
} from '@hospy/util-api/requests';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ListingsSliceName } from './constants';

export const ThunkFindAllListings = createAsyncThunk(
	`${ListingsSliceName}/${RequestFindAllListings.name}`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllListings(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);