import { ITraState } from './tra.interfaces';

export const TRA_SLICE_NAME = 'tra';

export const initialState = {
	appData: undefined,
	settings: {
		error: '',
		loading: 'idle',
		data: undefined
	},
	settingsV2: {
		error: '',
		loading: 'idle',
		data: undefined
	},
	rntValidate: {
		error: '',
		loading: 'idle',
		data: undefined
	},
	sireTraSettings: {
		error: '',
		loading: 'idle',
		data: undefined
	},
	cloudbedsGetRooms: {
		error: '',
		loading: 'idle',
		data: undefined
	},
	deletePropertyIntegration: {
		error: '',
		loading: 'idle',
		data: undefined
	},
	updateSireTraSettings: {
		error: '',
		loading: 'idle'
	},
	removeSireTraSettings: {
		error: '',
		loading: 'idle'
	},
	fieldMapping: {
		loading: 'idle',
		error: '',
		data: undefined
	},
	refreshFieldMapping: {
		error: '',
		loading: 'idle'
	},
	listings: {
		loading: 'idle',
		data: undefined,
		error: ''
	},
	createListing: {
		loading: 'idle',
		error: ''
	},
	editListing: {
		loading: 'idle',
		error: ''
	},
	deleteListingState: {
		loading: 'idle',
		error: ''
	},
	createSireTraSettingsData: {
		loading: 'idle',
		error: ''
	},
	allListings: {
		loading: 'idle'
	},
	updatedFieldMapping: {
		loading: 'idle'
	},
	ttlockFieldMapping: {
		loading: 'idle',
		error: '',
		data: undefined
	},
	updatedIotLockFieldMapping: {
		loading: 'idle',
		error: ''
	},
	flexipassFieldMapping: {
		loading: 'idle',
		error: '',
		data: undefined
	}
} as ITraState;
