/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { ListingsInitialState, ListingsSliceName } from './constants';
import { IListingsInitialState } from './interface';

import { ThunkFindAllListings } from './thunks';

export const partnerListingsSlice = createSlice({
	name: ListingsSliceName,
	initialState: ListingsInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(
				ThunkFindAllListings.pending,
				(state: IListingsInitialState) => {
					state.findAllListings.loading = 'pending';
				}
			)
			.addCase(
				ThunkFindAllListings.rejected,
				(state: IListingsInitialState, payload: any) => {
					state.findAllListings = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkFindAllListings.fulfilled,
				(state: IListingsInitialState, { payload }: any) => {
					state.findAllListings = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);
	}
});
