/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Property,
	addProperty,
	fetchUserInformation,
	resetAddProperty,
	resetUpdateProperty,
	useAppDispatch,
	useAppSelector,
	updateProperty as userUpdateProperty
} from '@hospy/store';
import {
	getCityService,
	getCountryService,
	getPropertyService,
	getStateService
} from '@hospy/util-api';
import { cognitoService } from '@hospy/util-auth';
import { Form, message } from 'antd';
import { propertyType } from 'libs/store/src/lib/common/common.constants';
import unionBy from 'lodash/unionBy';
import React, { useEffect, useImperativeHandle, useState } from 'react';

let loadingCountriesFirstTime = true; // TODO: Optimizar porque carga 2 veces
let fetchingEditData = false;
export interface usePropertyFormProps {
	onComplete: () => void;
	isCreate?: boolean;
	onBlocking: (blocking: boolean) => void;
	ref: React.ForwardedRef<unknown>;
}

const usePropertyForm = (props: usePropertyFormProps) => {
	const dispatch = useAppDispatch();
	const {
		addProperty: createProperty,
		updateProperty,
		propertyIdToUpdate
	} = useAppSelector(({ user }) => user);

	const [loadingCountries, setLoadingCountries] = useState(false);
	const [loadingStates, setLoadingStates] = useState(false);
	const [loadingCities, setLoadingCities] = useState(false);
	const [countries, setCountries] = useState<Array<any>>([]);
	const [states, setStates] = useState<Array<any>>([]);
	const [cities, setCities] = useState<Array<any>>([]);
	const [currencies, setCurrencies] = useState<any[]>([]);
	const [timeZones, setTimeZones] = useState<Array<any>>([]);
	const properties = propertyType;

	const broadcastChannels = [
		{
			label: 'Me lo recomendaron',
			value: 'referred'
		},
		{
			label: 'Me enviaron un email',
			value: 'email'
		},
		{
			label: 'Por medio de Cloudbeds',
			value: 'cloudbeds'
		},
		{
			label: 'Google',
			value: 'google'
		},
		{
			label: 'Redes sociales',
			value: 'social media'
		},
		{
			label: 'En una feria o evento',
			value: 'fair and event'
		},
		{
			label: 'Mauro Hofra',
			value: 'mauro hofra'
		},
		{
			label: 'Asohost',
			value: 'asohost'
		}
	];

	const [selectedProperty, setSelectedProperty] = useState<Property | null>(
		null
	);
	const [loadingProperty, setLoadingProperty] = useState(false);

	const [editLoaded, setEditLoaded] = useState({
		countryEditLoaded: 'waiting',
		stateEditLoaded: 'waiting',
		cityEditLoaded: 'waiting',
		timezonesEditLoaded: 'waiting'
	});

	const [form] = Form.useForm();

	const stateId = Form.useWatch('state_id', form);
	const countryId = Form.useWatch('country_id', form);

	useEffect(() => {
		if (propertyIdToUpdate) {
			const blocking =
				loadingProperty || loadingCountries || loadingStates;
			props.onBlocking(blocking);
		}
	}, [loadingProperty, loadingCountries, loadingCities, loadingStates]);

	useEffect(() => {
		if (
			countries.length > 0 &&
			selectedProperty &&
			editLoaded.countryEditLoaded !== 'done'
		) {
			const { name, type, location, currency, channel, phone } =
				selectedProperty || {};
			const { country } = location || {};
			form.setFieldsValue({
				name,
				type,
				country_id: country?.iso,
				currency,
				channel,
				phone
			});
			setEditLoaded({ ...editLoaded, countryEditLoaded: 'done' });
		}
	}, [selectedProperty]);

	useEffect(() => {
		const { phone } = selectedProperty || {};
		if (countryId && phone) {
			form.setFieldsValue({
				phone: undefined
			});
			setTimeout(() => {
				form.setFieldsValue({
					phone
				});
			}, 100);
		}
	}, [countryId, selectedProperty]);

	useEffect(() => {
		if (
			countries.length > 0 &&
			states.length > 0 &&
			selectedProperty &&
			!form.getFieldValue('state_id') &&
			editLoaded.stateEditLoaded !== 'done'
		) {
			const { location } = selectedProperty || {};
			const { state } = location || {};
			form.setFieldsValue({
				state_id: state?.iso
			});
			setEditLoaded({ ...editLoaded, stateEditLoaded: 'done' });
		}
	}, [states, countries]);

	useEffect(() => {
		if (
			countries.length > 0 &&
			states.length > 0 &&
			cities.length > 0 &&
			selectedProperty &&
			!form.getFieldValue('city_id') &&
			editLoaded.cityEditLoaded !== 'done'
		) {
			const { location } = selectedProperty || {};
			const { city } = location || {};
			form.setFieldsValue({
				city_id: city?._id
			});
			setEditLoaded({ ...editLoaded, cityEditLoaded: 'done' });
		}
	}, [cities, countries, states]);

	useEffect(() => {
		if (
			countries.length > 0 &&
			timeZones &&
			timeZones.length > 0 &&
			selectedProperty &&
			!form.getFieldValue('timezone')
		) {
			const { timezone } = selectedProperty || {};
			form.setFieldsValue({
				timezone
			});
		}
	}, [timeZones, countries]);

	useEffect(() => {
		form.setFieldsValue({
			state_id: undefined,
			city_id: undefined,
			timezone: undefined
		});
		loadState(countryId);

		if (!propertyIdToUpdate && countryId && props.isCreate) {
			setEditLoaded({
				...editLoaded,
				stateEditLoaded: 'done',
				cityEditLoaded: 'done'
			});
		}
	}, [countryId]);

	useEffect(() => {
		form.setFieldsValue({
			city_id: undefined
		});
		loadCities(stateId);
	}, [stateId]);

	useEffect(() => {
		if (createProperty.loading === 'succeeded') {
			form.resetFields();
			if (props.onComplete) props.onComplete();
		}
	}, [createProperty.loading]);

	useEffect(() => {
		if (updateProperty.loading === 'succeeded') {
			form.resetFields();
			if (props.onComplete) props.onComplete();
		}
	}, [updateProperty.loading]);

	useEffect(() => {
		if (timeZones && timeZones.length === 1) {
			form.setFieldsValue({
				timezone: timeZones[0]?.value
			});
		}
	}, [timeZones]);

	useImperativeHandle(props.ref, () => ({
		reset() {
			resetForm();
		}
	}));

	const resetForm = () => {
		form.resetFields();
		if (!propertyIdToUpdate) loadCountries();
		setEditLoaded({
			countryEditLoaded: 'waiting',
			stateEditLoaded: 'waiting',
			cityEditLoaded: 'waiting',
			timezonesEditLoaded: 'waiting'
		});
		if (propertyIdToUpdate) props.onBlocking(false);
	};

	useEffect(() => {
		if (propertyIdToUpdate && !fetchingEditData) {
			fetchingEditData = true;
			loadEditData();
		}
	}, [propertyIdToUpdate]);

	useEffect(() => {
		if (countries.length === 0) {
			loadingCountriesFirstTime = true;
		}
		if (loadingCountriesFirstTime && !propertyIdToUpdate) {
			loadingCountriesFirstTime = false;
			loadCountries();
		}
	}, [countries.length]);

	useEffect(() => {
		if (createProperty.loading === 'failed') {
			message.error('Error al intentar crear el negocio');
			return;
		}
		if (createProperty.loading === 'succeeded') {
			message.success('Negocio creado exitosamente');
			dispatch(resetAddProperty());
			return;
		}
	}, [createProperty.loading]);

	useEffect(() => {
		if (updateProperty.loading === 'failed') {
			message.error('Error al intentar actualizar el negocio');
			return;
		}
		if (updateProperty.loading === 'succeeded') {
			message.success('Negocio actualizado exitosamente');
			dispatch(resetUpdateProperty());
			refreshUserData();
			return;
		}
	}, [createProperty.loading, updateProperty.loading]);

	const getProperty = async (id: string | undefined) => {
		setLoadingProperty(true);
		try {
			const { data = [] } = await getPropertyService(id as string);
			if (data && data.length > 0) {
				setSelectedProperty(data[0]);
			}
			setLoadingProperty(false);
		} catch (error: any) {
			console.error(error.message);
			setLoadingProperty(false);
		}
	};

	const loadEditData = async () => {
		await loadCountries();
		await getProperty(propertyIdToUpdate);
		fetchingEditData = false;
	};

	const loadCountries = async () => {
		setStates([]);
		setCities([]);
		setTimeZones([]);
		setLoadingCountries(true);
		const { data } = await getCountryService(
			`limit=250&fields=["currency","currencyName", "currencySymbol", "iso2", "name", "translations.es", "_id","timezones"]`
		);
		let arr = data.map(({ currency, currencyName }: any) => ({
			currency,
			currencyName: { en: currencyName, es: currencyName }
		}));
		arr = unionBy(arr, (e: any) => e.currency);
		setCurrencies(
			arr.map((e: any) => ({
				value: e.currency,
				label: `${e.currency} | ${e.currencyName['es']}`
			}))
		);
		setCountries(
			data.map(({ iso2, name, translations, _id, timezones }: any) => ({
				value: iso2,
				label: translations?.es || name,
				name,
				_id,
				timezones
			}))
		);
		setLoadingCountries(false);
	};

	const loadState = async (countryIso: string) => {
		if (countryIso) {
			setLoadingStates(true);
			const countryId =
				(countries || []).find((e: any) => e.value === countryIso)
					?._id || '';
			setTimeZones(
				(countries || [])
					.find((e: any) => e.value === countryIso)
					?.timezones.map(({ zoneName }: any) => ({
						value: zoneName,
						label: zoneName
					}))
			);
			const { data } = await getStateService({ countryId });
			setStates(
				data.map(({ stateCode, name, _id }: any) => ({
					value: stateCode,
					label: name,
					_id
				}))
			);
			setCities([]);
			setLoadingStates(false);
		}
	};

	const loadCities = async (stateCode: string) => {
		if (stateCode) {
			setLoadingCities(true);
			const stateId =
				(states || []).find((e: any) => e.value === stateCode)?._id ||
				'';
			const { data } = await getCityService({ stateId });
			setCities(
				data.map(({ name, _id }: any) => ({ value: _id, label: name }))
			);
			setLoadingCities(false);
		}
	};

	const onSubmit = async ({
		name,
		type,
		currency,
		timezone,
		phone,
		// channel,
		...data
	}: any) => {
		const country = {
			_id: countries.find((e) => e.value === data.country_id)?._id,
			iso: data.country_id,
			name: countries.find((e) => e.value === data.country_id)?.label
		};
		let state = undefined;
		let city = undefined;

		if (data.state_id) {
			state = {
				_id: states.find((e) => e.value === data.state_id)?._id,
				iso: data.state_id,
				name: states.find((e) => e.value === data.state_id)?.label
			};
		}

		if (data.city_id) {
			city = {
				_id: data.city_id,
				name: cities.find((e) => e.value === data.city_id)?.label
			};
		}

		const propertyData = {
			name,
			type,
			currency,
			timezone,
			location: {
				country,
				state,
				city
			},
			phone,
			// channel
		};

		if (propertyIdToUpdate) {
			await dispatch(
				userUpdateProperty({
					data: propertyData,
					propertyId: propertyIdToUpdate as string
				})
			);
		} else {
			await dispatch(addProperty(propertyData));
		}
	};

	const refreshUserData = async () => {
		const session = await cognitoService.getSession();
		await dispatch(
			fetchUserInformation({ user: session?.idToken?.payload })
		);
	};

	return {
		form,
		properties,
		countries,
		states,
		cities,
		currencies,
		timeZones,
		onSubmit,
		createProperty,
		loadingCountries,
		loadingStates,
		loadingCities,
		loadingProperty,
		broadcastChannels,
		updateProperty,
		propertyIdToUpdate,
		countryId
	};
};

export default usePropertyForm;
